.create_chatbot_dialog {
  & > div {
    width: 640px;
  }

  .dialog_body {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 32px;
    row-gap: 32px;
  }

  .select_row {
    width: 100%;
  }
  .label {
    font-size: 1rem;
    font-weight: 700;
    padding-bottom: 0.5rem;
    color: #344767;
    padding-left: 0.25rem;
  }
  .help {
    color: rgb(103, 116, 142);
    padding-left: 0.25rem;
    padding-bottom: 0.5rem;
  }
  .model_selector_container {
    border: 1px solid rgb(210, 214, 218);
    border-radius: 1rem;
    padding: 1rem;
  }
  .model_selector {
    /*padding-bottom: 32px;*/
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    justify-content: center;

    .model_item {
      display: flex;
      align-items: center;
      gap: 8px;
      border-radius: 0.75rem;
      background-color: #f8f9fa;
      padding: 8px 12px;
      cursor: pointer;
      width: 50%;
      max-width: 258px;

      &.selected {
        box-shadow: 0 0 0 3px rgba(131, 88, 255, 0.6);
        background-color: rgba(#8358ff, 0.1);
      }
      .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 12px;
      }
      .name_column {
        display: flex;
        flex-direction: column;
        gap: 4px;
        .name {
          color: rgb(52, 71, 103);
          font-weight: 600;
          font-size: 1rem;
        }
        .context {
          color: rgb(103, 116, 142);
          font-size: 0.875rem;
          font-weight: 600;
          line-height: 1.5;
          letter-spacing: 0.02857em;
        }
      }
    }
  }
}
