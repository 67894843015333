.create_form_dialog {
  .dialog_body {
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 32px;
    padding-bottom: 32px;

    .react-select {
      width: 100%;
    }
    .select_row {
      width: 100%;
    }
    .label {
      font-size: 0.75rem;
      font-weight: 700;
      padding-bottom: 0.5rem;
      color: #344767;
      padding-left: 0.25rem;
    }
  }
  .buttons {
    column-gap: 12px;
  }
}
