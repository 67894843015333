@import "./layout";
@import "./button.scss";
@import './modal.scss';
@import "./responsive.scss";
@import "./forms.scss";
@import "./spinner.scss";
@import "./toggle_switch.scss";

#root, body {
    width: 100%;
    height: 100vh;
}