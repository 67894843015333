.copy_link_dialog {
  .dialog_body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 32px;
    padding-bottom: 32px;
    padding-top: 24px;

    .share_notice {
      background-color: #fff3cd;
      border-radius: 6px;
      padding: 12px 16px;
      border: 1px solid #ffe69c;
      color: #664d02;
    }
  }
  .buttons {
    column-gap: 12px;
  }
}
