.campaign_list_main {
  h1 {
    margin: 0;
    font-weight: 700 !important;
    font-size: 1rem;
    line-height: 1.625;
    color: #344767;
  }
  .body_content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;

    .content_header {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
}
.card {
  box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
  border-radius: 1rem;
  background-color: #fff;
  padding: 24px;

  .card_header {
    display: flex;
    justify-content: space-between;
  }

  .card_title {
    margin: 0;
    font-weight: 700 !important;
    font-size: 1rem;
    line-height: 1.625;
    color: #344767;
  }

  .card_actions {
    display: flex;
    align-items: center;
    column-gap: 12px;
  }
}
.campaign_tiles {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
.campaign_tile {
  background-color: #f8f9fa;
  padding: 24px;
  column-gap: 24px;
  width: 100%;
  .tile_body {
    flex-grow: 1;
    cursor: pointer;
  }
  .tile-name {
    font-size: 14px;
    color: #344767;
    font-weight: 600;
    padding-bottom: 16px;
  }
  .metadata_row {
    display: flex;
    column-gap: 12px;
    font-size: 12px;
    .meta_name {
      color: rgb(103, 116, 142);
      min-width: 80px;
    }
    .meta_value {
      color: #344767;
    }
  }
  .status_row {
    padding-top: 8px;
  }
  .tile-actions {
    display: flex;
    align-items: flex-start;
    column-gap: 32px;

    .confirm_container {
      position: relative;
      cursor: pointer;
      .cover {
        position: fixed;
        inset: 0;
      }
      .confirm_panel {
        position: absolute;
        z-index: 999;
        top: 24px;
        left: 0px;
        padding: 12px 14px;
        background-color: #fff;
        border: 1px solid #b8b8b8;
        border-radius: 5px;

        button {
          color: #fff;
          padding: 0.5rem 2rem;
          margin: 0;
        }
      }
    }
  }
  .tile-action {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    color: #344767;
    svg {
      margin-right: 6px;
    }
  }
}
.create_campaign_dialog {
  .dialog_body {
    padding-bottom: 32px;
  }
  .buttons {
    column-gap: 12px;
  }
}
