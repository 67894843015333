.error_state_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}
.error_state_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 320px;

  .error_state_icon_wrapper {
    background-color: #fff3cd;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .error_state_text {
    text-align: center;
    color: rgb(103, 116, 142);
    font-size: 1rem;
  }
  .error_state_button {
    a,
    a:focus,
    a:visited,
    a:active {
      text-decoration: none;
    }
  }
}
