.shared_page_layout {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 48px;
}
.shared_page_layout_body {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  width: 100%;
}
.powered_by {
  position: absolute;
  bottom: -24px;
  right: 14px;
  font-size: 14px;
  color: rgb(103, 116, 142);

  a,
  a:visited,
  a:active,
  a:focus {
    color: #344767;
    text-decoration: none;
  }
}
