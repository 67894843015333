.create_endpoint_dialog {
  .dialog_body {
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 32px;
    padding-bottom: 32px;

    .react-select {
      width: 100%;
    }
    .select_row {
      width: 100%;
    }
    .label {
      font-size: 0.75rem;
      font-weight: 700;
      padding-bottom: 0.5rem;
      color: #344767;
      padding-left: 0.25rem;
    }
  }
  .url_container {
    margin-top: 0;
    margin-bottom: 24px;
    display: flex;
    align-items: center;

    .http-method {
      color: #fff;
      background-color: #5cb85c;
      padding: 6px 12px;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.42857143;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      border: 1px solid transparent;
      border-radius: 4px;
    }

    .rest-endpoint {
      margin-left: 10px;
      margin-right: 6px;
    }
    input {
      width: 110px;
    }
  }
  .buttons {
    column-gap: 12px;
  }
}
