.chatbot_not_found_state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  background-color: #fff;
  gap: 24px;

  .chatbot_not_found_state_icon {
    background-color: #f8f9fa;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .chatbot_not_found_state_text {
    text-align: center;
    color: rgb(103, 116, 142);
    font-size: 1rem;
    padding: 0 24px;
  }
}
