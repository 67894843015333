.hub_models_content {
  display: flex;
  flex-direction: column;
  h1 {
    margin: 0;
    font-weight: 700 !important;
    font-size: 2rem;
    line-height: 1.625;
    color: #344767;
    padding-bottom: 16px;
  }
  .section_caption {
    color: #67748e;
    font-weight: 500;
    font-size: 1.6rem;
    padding-top: 12px;
    padding-bottom: 24px;
  }
  .text_models_caption {
    margin-top: 36px;
  }
  .model_list {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
  }
  .model_item {
    cursor: pointer;
    padding: 12px !important;

    .model_image {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 260px;
      width: 260px;
      position: relative;
      img {
        border-top-left-radius: 0.6rem;
        border-top-right-radius: 0.6rem;

        /*box-shadow: 0 3px 6px rgba(33,33,33,0.15);*/
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
    .model_title {
      width: 260px;
      max-width: 260px;
      padding-top: 16px;
      padding-bottom: 16px;

      text-align: center;
      font-size: 1.2rem;
      font-weight: 600;
      color: rgb(52, 71, 103);
    }
    .model_description {
      width: 260px;
      max-width: 260px;
      font-size: 1rem;
      color: rgb(109, 123, 147);
      text-align: center;
    }
  }
}
