.subscription_content_panel {
  .actions_panel {
    display: flex;
    margin-bottom: 12px;
    & > button:not(:last-child) {
      margin-right: 15px;
    }
    .app-btn {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  } // actions panel
  .summary {
    display: flex;
    flex-direction: column;
    box-shadow: 0 0.3em 1.2em rgba(204, 204, 204, 0.6);
    background-color: #fff;
    border-radius: 3px;
    padding: 20px;

    & > div {
      display: flex;
      align-items: center;
      .value {
        margin-left: 18px;
      }
      svg {
        margin-right: 12px;
      }
      &:not(&:last-child) {
        margin-bottom: 16px;
      }
    }
  }
  .subscriptio_section {
    display: flex;
    flex-direction: column;
    box-shadow: 0 0.3em 1.2em rgba(204, 204, 204, 0.6);
    background-color: #fff;
    border-radius: 3px;
    padding: 20px;
    margin-top: 24px;
  }
  .orders_panel {
    .order {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > div {
        display: flex;
        align-items: center;
        svg {
          margin-right: 8px;
        }
        a,
        a:active,
        a:hover,
        a:visited {
          color: #333;
        }
      }
      &:not(:last-child) {
        border-bottom: 1px solid #efefef;
        margin-bottom: 16px;
      }
    }
  }
}

.limits_panel {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0.3em 1.2em rgba(204, 204, 204, 0.6);
  background-color: #fff;
  border-radius: 3px;
  padding: 20px;
  margin-top: 24px;

  .progress_bar_container {
    width: 296px;
    padding-top: 24px;
  }
  .meter {
    /*height: 20px;*/
    position: relative;
    background: rgb(240, 243, 244);
    border-radius: 25px;
    padding: 6px;
    box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);

    & > span {
      display: block;
      height: 100%;
      text-align: center;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      background-color: rgb(208, 146, 219);
      color: #fff;
      background-image: linear-gradient(
        center bottom,
        rgb(160, 105, 226) 37%,
        rgb(235, 170, 211) 69%
      );
      box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
        inset 0 -2px 6px rgba(0, 0, 0, 0.4);
      position: relative;
      overflow: hidden;

      &.orange {
        background-color: #f1a165;
        background-image: linear-gradient(to bottom, #f1a165, #f36d0a);
      }

      &.red {
        background-color: #f0a3a3;
        background-image: linear-gradient(to bottom, #f0a3a3, #f42323);
      }
      &.full {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
    }
  }
}

.upgrade_section {
  &__title {
    font-weight: bold;
  }
}
.upgrade_options_container {
  display: flex;
  column-gap: 24px;
  margin-top: 16px;

  .upgrade_option {
    padding: 24px;
    border: 2px solid #dee2e6;
    border-radius: 4px;

    &:not(.current) {
      cursor: pointer;

      &:hover {
        background-color: rgba(#f2dad3, 0.5);
      }
    }

    &.current {
      background-color: #f2dad3;
      border-color: #aeaeae;
      border-width: 1px;
    }

    .option_title {
      display: flex;
      align-items: center;
      color: #383838;
    }
  }
}
.compare_plans {
  padding-top: 16px;
  a,
  a:hover,
  a:active,
  a:visited {
    color: #660047;
    text-decoration: none;
  }
}
.upgrade_confirm_text {
  font-size: 16px;
  color: #383838;
  padding: 24px;
  text-align: center;
}
