.chatbot_reply_error_state {
  display: flex;
  gap: 24px;
  padding: 24px;
  border-radius: 6px;

  box-shadow: inset 0 0 10px #f1aeb5;
  background-color: #fff;

  .openai_avatar {
    display: flex;
    padding: 8px;
    border-radius: 6px;
    background-color: rgb(117, 169, 155);
  }
  .message_item {
    flex-grow: 1;
    color: #344767;
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
