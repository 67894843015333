.prompt_form_templates {
  display: flex;
  flex-direction: column;
  height: 301px;

  .template_search {
    display: flex;
    align-items: center;
    gap: 12px;
    padding-bottom: 8px;
    .search_field {
      flex-grow: 1;
    }
    .add_button {
      display: flex;
      align-items: center;
      .btn.btn-sm {
        padding: 6px 16px;
        margin-bottom: 0;
      }
    }
  }
  .prompt_template_list {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .no_matches_state {
      display: flex;

      align-items: center;
      gap: 12px;
      padding: 24px 0;
      color: rgb(52, 71, 103);
    }
    .template_spinner_container {
      padding-top: 24px;
    }
    .template_item {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 10px 0;
      color: #344767;
      cursor: pointer;

      &:hover {
        background-color: #fff;
        border-radius: 6px;
      }

      .template_icon {
        display: flex;
        align-items: center;
        padding-right: 4px;
      }
      .name {
        font-weight: 500;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-width: fit-content;
        max-width: 280px;
        color: rgb(52, 71, 103);
      }
      .content {
        flex-grow: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: rgb(103, 116, 142);
      }
    }
  }
}
