.model_job_list {
  .job_list_title {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500 !important;
    font-size: 1.4rem;
    line-height: 1.625;
    color: #344767;
    margin-top: -12px;
  }
  .jobs_help {
    color: rgb(103, 116, 142);
    font-size: 1rem;
    padding-top: 12px;
  }
  .recent_jobs_for_model {
    h4 {
      margin-top: 0;
      margin-bottom: 12px;
    }
    .recent_jobs_list {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .job_list_headers {
        display: grid;
        gap: 16px;
        grid-template-columns: 1fr 70px 60px 60px 80px;
        font-size: 13px;
        color: #67748e;
        padding: 12px;
        padding-bottom: 0;

        &:first-child {
          padding-left: 12px;
        }
        &:last-child {
          padding-right: 12px;
        }
      }
      .job_row {
        display: grid;
        gap: 16px;
        grid-template-columns: 1fr 70px 60px 60px 80px;
        padding: 12px;
        background-color: #f8f9fa;
        cursor: pointer;

        .model_name {
          color: #344767;
          font-size: 14px;
          font-weight: 600;
        }
        .input_column {
          color: #344767;
          font-size: 13px;
        }
        .status {
        }
        .timing {
          color: #344767;
          font-size: 13px;
        }
        .created {
          color: #67748e;
          font-size: 13px;
        }
      }
    }
  }
}
