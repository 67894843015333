.campaign_submission_main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    & > .card {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
    }
    .title_row {
        grid-column-start: 1;
        grid-column-end: 3;
        display: flex;
        justify-content: space-between;
    }
    .title_text {
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.375;
        color: #344767;
        display: flex;
        align-items: center;
        svg {
            margin-right: 8px;
        }
    }

    .metadata_row {
        display: flex;
        column-gap: 12px;
        font-size: 14px;
        .meta_name {
            display: flex;
            align-items: center;
            color: rgb(103,116,142);
            

        }
        .meta_value{
            color: #344767;
        }
        .meta_link {
            color: rgb(52, 71, 103);
            font-weight: bold;
            cursor: pointer;
        }
    }
    .title_column {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
    }
    .title_actions {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        column-gap: 20px;

        .confirm_container {
            position: relative;                
            cursor: pointer;
            .cover {
                position: fixed;
                inset: 0;
              }
            .confirm_panel {
              position: absolute;
              z-index: 999;
              top: 12px;
              left: 0px;
              padding: 12px 14px;
              background-color: #fff;
              border: 1px solid #b8b8b8;
              border-radius: 5px;

              button {
                color: #fff;
                padding: 0.5rem 2rem;
                margin: 0;
              }
            }
          }
    }    
    .content_row {
        display: flex;
        flex-grow: 1;
        padding: 48px;
        /*justify-content: center;*/
    }
    .video_placeholder {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 18px;
        padding: 48px;
        color: #8392ab;
        width: 100%;

        .placeholder_text {
            text-align: center;
            max-width: 600px;
        }
    }
    .video_container {
        width: 800px;
        max-width:800px;
        max-height:600px;
        height: 600px;
        & > div {
            box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        }
        /*
        & > div, video {
            border-radius: 1rem;
        }
        */
    }
    .tile-action-approve {
        color: #830866;
    }
    .tile-action-reject {
        color: #ea0606;
    }

    .question_list {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        
        padding: 12px 12px;

        border-top-left-radius: 0.75rem;
        border-bottom-left-radius: 0.75rem;

        .question_item {
            display: flex;
            width: 100%;
            padding: 12px 12px;
            column-gap: 12px;
            align-items: center;
            cursor:pointer;

            &.done {
                color: #fff;
            }
            &.missing {
                color: #aaa;
            }
            &.current {
                box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
                background-color: rgba(#fff,0.3);
                border-radius: 0.75rem;
                .num {
                    border: 1px solid #bbb;
                }
            }
        }
        .num {
            border: 1px solid grey;
            border-radius: 50%;
            width: 36px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            &.done {
                
            }
        }
        .qtext {
            flex-grow: 1;
        }
    }
}
.vjs-poster, video {
}