.toggle_switch_container_inline,
.toggle_switch_container {
  align-items: center;
}
.toggle_switch_container_inline {
  display: inline-flex;
  /*margin-left: 15px;*/
}
.toggle_switch_container {
  display: flex;
  margin-top: 32px;
  font-size: 15px;
}
.switch_label {
  color: rgb(145, 151, 163);
  cursor: pointer;
}
.switch_label_active {
  color: #000;
  cursor: pointer;
}

.toggle-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 26px;
  height: 10px;
}

label.toggle-switch {
  margin-bottom: 0 !important;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(186, 190, 193);
  transition: 0.4s;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 0px;
  bottom: -2px;
  /*
  background-color: rgb(107, 116, 209);
  */
  background-color: #344767;
  transition: 0.4s;
}

input:checked + .toggle-slider {
  /*background-color: #1849ab;*/
}

input:focus + .toggle-slider {
  /*box-shadow: 0 0 0 4px rgba(21, 156, 228, 0.7);
  outline: none;*/
}

input:checked + .toggle-slider:before {
  transform: translateX(12px);
}

.toggle-slider.toggle-round {
  border-radius: 5px;
}

.toggle-slider.toggle-round:before {
  border-radius: 50%;
}
