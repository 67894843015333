.preview_row {
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  flex-direction: column;

  .preview_body {
    display: grid;
    grid-template-columns: 1fr 36px 1fr;
  }
  .between {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .form_fields_container {
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    gap: 24px;
  }
  .response_label {
    color: rgb(103, 116, 142);
    padding-bottom: 6px;
  }
  .choice_container {
    padding: 16px;
    border: 1px solid #d2d6da;
    border-radius: 4px;
    background-color: #f8f9fa;
  }
}
