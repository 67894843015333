.create_workspace_dialog {
  color: #333;
  .workspace_form {
    margin-top: 48px;
    padding-bottom: 24px;
    padding-left: 12px;
    position: relative;
    svg {
      position: absolute;
      left: 24px;
      top: 11px;

      @media (max-width: 767.98px) {
        & {
          left: 19px;
        }
      }
    }
    &.loading {
      input {
        color: #155724;
        background-color: #d4edda;
        &:focus,
        &:active {
          outline: none;
          box-shadow: 0 0 0 4px #c3e6cb;
        }
      }
    }
    input {
      border: unset;
      font-size: 16px;
      padding: 15px;
      padding-left: 50px;
      /*box-shadow: 0 3px 6px rgba(33,33,33,0.2);*/
      border-radius: 5px;
      background-color: rgb(240, 243, 244);
      width: 90%;

      @media (max-width: 767.98px) {
        & {
          padding-left: 40px;
        }
      }

      &:focus,
      &:active {
        outline: none;
        /*box-shadow: 0 3px 6px rgba(77,64,132,0.3);*/
        box-shadow: 0 0 0 4px rgba(77, 64, 132, 0.3);
      }
    }
  } // end of url_form
  .buttons {
    .app-btn-no-border {
      margin-left: 24px;
    }
  }
}

.delete_disabled_hint {
  margin-top: 24px;
  padding: 12px 24px;
  background-color: #fff3cd;
  color: #856404;
  border-color: #ffeeba;
  border-radius: 6px;
}

.workspace_settings_main {
  .settings_panel {
    box-shadow: 0 0.3em 1.2em rgba(204, 204, 204, 0.6);
    background-color: #fff;
    border-radius: 3px;
    padding: 40px;
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
  input.origin-control {
    margin-bottom: 12px;
    padding: 3px 10px;
    border-radius: 5px;
    background-color: #fff;
    color: rgb(51, 51, 51);
    font-size: 16px;
    width: 320px;
    height: 30px;
    border: 1px solid rgb(211, 211, 211);

    &:focus,
    &:active {
      outline: none;
      /*box-shadow: 0 3px 6px rgba(77,64,132,0.3);*/
      box-shadow: 0 0 0 2px rgba(77, 64, 132, 0.3);
    }
  }

  .confirm_container {
    position: relative;
    z-index: 10000;
    .cover {
      position: fixed;
      inset: 0;
    }
    .confirm_panel {
      position: absolute;
      z-index: 10000;
      top: 12px;
      left: 0;
      padding: 12px 14px;
      background-color: #fff;
      border: 1px solid #b8b8b8;
      border-radius: 5px;

      button {
        &.app-btn-red.app-btn {
          font-size: calc(14px * 1 / var(--de-scale));
          padding: 6px 12px;
          border-radius: 4px;
        }
      }
    }
  }
  .api_key_panel {
    margin-bottom: 24px;
  }
}

.workspace_users_panel {
  .members_panel {
    margin-bottom: 32px;
  }
  .invitations_panel {
    margin-top: 24px;
  }
}

.workspace_fonts_panel {
  margin-bottom: 32px;

  .font_list {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding-top: 24px;

    .font_item {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);

      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      align-items: center;

      & > div {
        display: flex;
        align-items: center;
        padding: 16px 24px;

        svg {
          margin-right: 16px;
        }
        &.actions > .app-btn {
          svg {
            margin-right: 0;
          }
        }
      }
      .date,
      .actions {
        justify-content: flex-end;
      }
    }
  }
}

.delete_font_dialog {
  .dialog_text {
    margin-top: 32px;
    margin-bottom: 32px !important;
    line-height: 1.6;
  }
  .buttons {
    .app-btn-no-border {
      margin-left: 24px;
    }
  }
}
