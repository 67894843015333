.hub_job_details {
  .breadcrumbs {
    display: flex;
    align-items: center;
    color: rgb(103, 116, 142);
    font-size: 0.875rem;
    padding-bottom: 8px;
    & > div {
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 6px;
      flex-basis: fit-content;
    }
  }
  .model_name {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 2rem;
    line-height: 1.375;
    color: #344767;
    column-gap: 12px;
    margin-top: 0px;
    margin-bottom: 12px;
  }
  .model_id {
    cursor: pointer;
    color: #67748e;
    font-weight: 500;
    font-size: 1.2rem;
    padding-bottom: 32px;
  }
  .summary_container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 24px;
    margin-bottom: 24px;

    .card {
      display: flex;
      gap: 12px;
      padding: 16px;
    }
    .icon_container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      /*background-color: rgb(248, 249, 250);*/
      border-radius: 0.5rem;

      &.draft {
        /*background-color: rgb(162, 202, 200);*/
        /*background-color: rgb(228, 229, 230);*/
        border: 2px solid #e4e5e6;
      }
      &.finished {
        background-color: #b4ecb4;
      }
    }
    .meta_container {
      .status_label {
        font-weight: 600 !important;
        font-size: 0.875rem !important;
        line-height: 1.5;
        color: #67748e;
      }
      .status_value {
        font-weight: 700 !important;
        font-size: 1.25rem;
        line-height: 1.375;
        color: #344767;

        &.finished {
          color: rgb(25, 135, 84);
        }
      }
    }
  }
  .model_job_details_card {
    .rest_api_container {
      display: grid;
      grid-template-columns: 1fr 36px 1fr;

      .label {
        padding-bottom: 16px;
        color: #67748e;
        font-weight: 500;
      }
    }
    .between {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
