.modal_dialog {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.9);

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  /*visibility: hidden;
  opacity: 0;
  pointer-events: none;*/
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.3s;
  /*
  &:target {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }
  */
  & > div {
    box-shadow: 0 8px 26px -4px hsl(0deg 0% 8% / 15%), 0 8px 9px -5px hsl(0deg 0% 8% / 6%);
    /*box-shadow: 0 13px 27px -5px rgb(50 50 93 / 25%), 0 8px 16px -8px rgb(0 0 0 / 30%), 0 -6px 16px -6px rgb(0 0 0 / 3%); */
  
    /*box-shadow: 0 6px 16px #c3c8cd; */
    /*box-shadow: 0 8px 20px rgba(33,33,33,0.4);*/
    border-radius: 10px;
    width: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2em;
    background: #ffffff;

    @media (max-width: 767.98px) {
      & {
        padding: 12px;
      }
    }
  }
  header {
    font-weight: bold;
  }
  h1, .modal_title {
    text-align: center;
    font-size: 150%;
    margin: 0 0 32px;
    color: #344767;
    font-weight: 600;
  }
  .options {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      height: 64px;
      width: 128px;
      padding: 0 20px;
      border: 2px solid rgb(230, 230, 233);
      border-radius: 12px;

      &:not(:last-child) {
        margin-right: 40px;
      }

      &:hover {
        border: 2px solid rgba(#7042c1, 0.5);
      }
      img {
        margin-right: 20px;
        max-width: 32px;
        max-height: 32px;
      }
    }
  }

  .modal_body {
    position: relative;
    transition: 0.8s;
    max-height: 80vh;
    overflow: auto;
    max-width: 70%;

    @media (max-width: 767.98px) {
      & {
        max-width: calc(100vw - 32px);
        max-height: calc(100vh - 32px);
      }
    }

    .modal_close_btn {
      border: 0;
      position: absolute;
      box-shadow: none;
      right: 16px;
      top: 16px;
      svg {
        margin-right: 0;
      }
    }
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.modal_dialog > div > div:not(:last-of-type) {
  margin-bottom: 15px;
}

.modal-close {
  color: #aaa;
  line-height: 50px;
  font-size: 80%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 70px;
  text-decoration: none;
  &:hover {
    color: black;
  }
}
