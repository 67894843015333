.workspace_uid {
  box-shadow: 0 0.3em 1.2em rgba(204, 204, 204, 0.6);
  background-color: #fff;
  border-radius: 16px;
  padding: 40px;
  margin-top: 24px;
  color: rgb(103, 116, 142);

  .help_text {
    padding-bottom: 16px;
  }

  .workspace_uid_value {
    background-color: #f0f3f4;
    color: rgb(124, 124, 124);
    padding: 9px 12px;
    border-radius: 4px;
    user-select: all;
    margin-bottom: 24px;
  }
  .help {
    padding-bottom: 16px;
    a,
    a:visited,
    a:hover,
    a:active {
      text-decoration: none;
      color: #660047;
    }
  }
}
