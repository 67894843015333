.prompt_details_main {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: max-content auto;
  gap: 24px;

  .title_row {
    grid-column-start: 1;
    grid-column-end: 3;
    display: flex;
    flex-direction: column;
  }
  .breadcrumbs {
    display: flex;
    align-items: center;
    color: rgb(103, 116, 142);
    font-size: 0.875rem;
    padding-bottom: 8px;
    & > div {
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 6px;
      flex-basis: fit-content;
    }
  }
  .title_block {
    display: flex;
    justify-content: space-between;
    .title_left {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
  .prompt_name {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 2rem;
    line-height: 1.375;
    color: #344767;
    column-gap: 12px;

    .edit {
      display: flex;
      cursor: pointer;
      height: 20px;
    }
    button {
      margin-bottom: 0;
    }
  }

  .metadata_row {
    display: flex;
    column-gap: 12px;
    font-size: 14px;
    .meta_name {
      color: rgb(103, 116, 142);
    }
    .meta_value {
      color: #344767;
    }
  }
  .title_column {
    display: flex;
    flex-direction: column;
  }
  .title_actions {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    column-gap: 20px;

    .confirm_container {
      position: relative;
      cursor: pointer;
      .cover {
        position: fixed;
        inset: 0;
      }
      .confirm_panel {
        position: absolute;
        z-index: 999;
        top: 12px;
        left: 0px;
        padding: 12px 14px;
        background-color: #fff;
        border: 1px solid #b8b8b8;
        border-radius: 5px;

        button {
          color: #fff;
          padding: 0.5rem 2rem;
          margin: 0;
        }
      }
    }
  }
  .question_add_placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 12px;
    color: #8392ab;
    cursor: pointer;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.375;
    border: 1px solid #dee2e6;
    border-radius: 1rem;
    padding: 24px;
  }

  .question_form {
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    textarea {
      font-size: 1rem;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    }

    .question_actions {
      display: flex;
      column-gap: 12px;
    }
  }
  .question_item {
    display: flex;
    column-gap: 24px;
    border-radius: 0.75rem;
    background-color: #f8f9fa;
    /*color: #344767;*/
    color: #333;
    padding: 24px;

    .number {
      width: 42px;
      min-width: 42px;
      height: 42px;
      min-height: 42px;
      border: 2px solid #ccc;
      color: #555;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      padding: 12px;
    }
    .item_content {
      display: flex;
      align-items: center;
      /*white-space: pre;*/
      white-space: pre-wrap;
      word-break: normal;
      overflow-wrap: anywhere;
      flex-grow: 1;
      font-size: 1rem;
      margin: 0;
    }
    .item_record {
      display: flex;
      align-items: flex-start;
      .btn_rec {
        min-width: 75px;
        color: #344767;
        padding: 0;
      }
    }
    .item_form {
      display: flex;
      flex-grow: 1;
      textarea {
        font-size: 1rem;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
          "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
          "Helvetica Neue", sans-serif;
      }
    }
    .item_actions {
      display: flex;
      align-items: flex-start;
      column-gap: 18px;
      button {
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        color: #344767;
        margin: 0;
        padding: 0;
        svg {
          margin-right: 0;
        }

        &.btn-text-icon {
          svg {
            margin-right: 6px;
          }
        }
        &.bg-gradient-dark {
          color: #fff;
          padding: 0.3rem 1rem;
        }
      }
      .confirm_container {
        position: relative;
        cursor: pointer;
        .cover {
          position: fixed;
          inset: 0;
        }
        .confirm_panel {
          position: absolute;
          z-index: 999;
          top: 24px;
          left: -76px;
          padding: 12px 14px;
          background-color: #fff;
          border: 1px solid #b8b8b8;
          border-radius: 5px;

          button {
            color: #fff;
            padding: 0.5rem 2rem;
          }
        }
      }
    }
  }
  .campaign_questions_body {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
  .submission_thumb_badge {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -8px;
    right: -8px;
    padding: 3px;
    border-radius: 50%;

    &.recording {
      background-color: #fbcf33;
    }
    &.ready {
      background-color: #7928ca;
    }
    &.approved {
      background-color: #17ad37;
    }
    &.rejected {
      background-color: #627594;
    }
  }
}
