@media (max-width: 767.98px) {
  .hidden-xs {
    display: none !important;
  }
}
.visible-xs,
.visible-xs-flex,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}
@media (max-width: 767.98px) {
  .visible-xs {
    display: block !important;
  }
  .visible-xs-flex {
    display: flex !important;
  }
  table.visible-xs {
    display: table !important;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}
