.request_item {
  display: grid;
  grid-template-columns: 240px 1fr 2fr 60px;
  gap: 24px;
  border-radius: 0.75rem;
  background-color: #f8f9fa;
  /*color: #344767;*/
  color: #333;
  padding: 24px;

  .date {
    color: #555;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px;
    & > div {
      display: flex;
      align-items: center;
      svg {
        margin-right: 8px;
      }
    }
    .time_diff {
      padding-left: 24px;
    }
  }
  .params_content {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
    /*white-space: pre;*/
    white-space: pre-wrap;
    word-break: normal;
    overflow-wrap: anywhere;
    flex-grow: 1;
    font-size: 1rem;
    margin: 0;
    .param_item {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 4px;
      label {
        padding-left: 4px;
        color: #344767;
      }
    }
  }
  .response_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    /*justify-content: center;*/

    /*white-space: pre;*/
    white-space: pre-wrap;
    word-break: normal;
    overflow-wrap: anywhere;
    flex-grow: 1;
    font-size: 1rem;
    margin: 0;
    .result_label {
      width: 100%;
      text-align: center;
      padding-bottom: 4px;
      color: #344767;
    }
  }
  .output_item {
    border: 1px solid #d2d6da;
    border-radius: 4px;
    padding: 16px;
  }
  .item_record {
    display: flex;
    align-items: flex-start;
    .btn_rec {
      min-width: 75px;
      color: #344767;
      padding: 0;
    }
  }
  .item_form {
    display: flex;
    flex-grow: 1;
    textarea {
      font-size: 1rem;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    }
  }
  .item_actions {
    display: flex;
    align-items: flex-start;
    column-gap: 18px;
    button {
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      color: #344767;
      margin: 0;
      padding: 0;
      svg {
        margin-right: 0;
      }

      &.btn-text-icon {
        svg {
          margin-right: 6px;
        }
      }
      &.bg-gradient-dark {
        color: #fff;
        padding: 0.3rem 1rem;
      }
    }
    .confirm_container {
      position: relative;
      cursor: pointer;
      .cover {
        position: fixed;
        inset: 0;
      }
      .confirm_panel {
        position: absolute;
        z-index: 999;
        top: 24px;
        left: -76px;
        padding: 12px 14px;
        background-color: #fff;
        border: 1px solid #b8b8b8;
        border-radius: 5px;

        button {
          color: #fff;
          padding: 0.5rem 2rem;
        }
      }
    }
  }
}
.submission_error {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #856404;
  font-size: 1rem;
  padding: 0.75rem 1.25rem;
  border: 1px solid #ffeeba;
  border-radius: 0.5rem;
  background-color: #fff3cd;
  margin-top: 1rem;
}
