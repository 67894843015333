.chat_list_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px;
  background-color: #fff;
  width: 220px;
  height: 220px;
  cursor: pointer;
  /*
  box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05);
  */
  box-shadow: rgba(112, 144, 176, 0.08) 14px 17px 40px 4px;
  border-radius: 1rem;
  .item_icon {
    display: flex;
    justify-content: center;
    padding-bottom: 24px;
  }
  .item_name {
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
    color: rgb(52, 71, 103);
    padding-bottom: 12px;
    max-width: 100%;
  }
  .model_name {
    display: flex;
    justify-content: center;
    font-size: 0.8rem;
    color: rgb(109, 123, 147);
    padding-bottom: 12px;
  }
  .item_date {
    display: flex;
    justify-content: center;
    font-size: 0.8rem;
    color: rgb(109, 123, 147);
  }
}
