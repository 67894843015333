.prompt_control {
  position: relative;

  background-color: rgb(248, 249, 250);
  border-radius: 6px;
  border: 1px solid #ededed;
  /*box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);*/

  .prompt_control_body {
    padding: 16px;

    .hidden_view {
      display: none;
    }
  }

  .view_navigation {
    display: flex;
    font-size: 14px;
    background-color: #fff;
    color: #67717d;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    .nav_item {
      cursor: pointer;
      padding: 16px;
      border-top: 1px solid #ededed;
      display: flex;
      align-items: center;
      gap: 8px;

      &.last {
        flex-grow: 1;
        cursor: unset;
      }
      &.selected {
        cursor: unset;
        background-color: rgb(248, 249, 250);
        border-top: unset;
        color: #344767;
        border-left: 1px solid #ededed;
        border-right: 1px solid #ededed;
        font-weight: 500;
        &.nav_textarea {
          border-left: unset;
        }
      }
      &.nav_textarea {
        border-bottom-left-radius: 6px;
      }
    }
  }
}
