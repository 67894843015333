.chat_details_main {
  h1 {
    margin: 0;
    font-weight: 700 !important;
    line-height: 1.625;
    color: #344767;
    font-size: 1.6rem;
  }
  .body_content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;

    .content_header {
      display: flex;
      justify-content: center;
      width: 100%;

      .content_header_body {
        max-width: 1016px;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 16px;
        border-bottom: 1px solid #dee2e6;
        .breadcrumbs {
          display: flex;
          align-items: center;
          color: rgb(103, 116, 142);
          font-size: 0.875rem;
          & > div {
            display: flex;
            align-items: center;
            cursor: pointer;
            gap: 6px;
            flex-basis: fit-content;
          }
        }
        .title_row {
          display: flex;
          justify-content: space-between;
          padding-top: 12px;
        }
      }
      .title_actions {
        display: flex;
        align-items: center;
        .btn {
          margin-bottom: 0;
        }
        .confirm_container {
          position: relative;
          cursor: pointer;
          .cover {
            position: fixed;
            inset: 0;
            z-index: 998;
          }
          .confirm_panel {
            position: absolute;
            z-index: 999;
            top: 12px;
            left: 0px;
            padding: 12px 14px;
            background-color: #fff;
            border: 1px solid #b8b8b8;
            border-radius: 5px;

            button {
              color: #fff;
              padding: 0.5rem 2rem;
              margin: 0;
            }
          }
        }
      }
    }
    .dropzone-wrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
    .content_container {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      position: relative;
    }
    .content_container_absolute {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;
      display: flex;
      justify-content: center;
      padding-bottom: 16px;
    }
    .content_width_container {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 1000px;
    }
    .messages_feed {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      gap: 32px;
      padding-top: 32px;
      padding-bottom: 32px;
    }
    .chat_actions_toolbar {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 24px;
      .toolbar_action {
        display: flex;
        align-items: center;
        gap: 8px;
        color: rgb(103, 116, 142);
        text-transform: uppercase;
        font-size: 0.75rem;
        color: #67748e;
        font-weight: 700;
        cursor: pointer;
      }
    }
    .prompt_control_container {
      display: flex;
      justify-content: center;
      .prompt_control_max_width {
        width: 100%;
        max-width: 1032px;
        padding: 16px;
        overflow-y: auto;
        scrollbar-gutter: stable;
      }
    }
  }
}
