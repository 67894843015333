.widget_prompt_textarea {
  position: relative;

  textarea.form-control {
    padding: 16px 24px;
    background-color: #fff;
  }
  /*
  .send_button {
    position: absolute;
    display: flex;
    bottom: 4px;
    right: 4px;
    cursor: pointer;
    background-color: #8358ff;
    padding: 6px;
    border-radius: 6px;
  }
  */
  .bottom_row {
    display: flex;
    gap: 12px;
    padding: 12px 0px 0;

    .options_panel {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .submit_on_enter {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 6px;
        font-size: 0.8rem;
        color: #555;
        padding-right: 16px;
      }
    }
    .send_button {
      display: flex;
      align-items: center;
      color: #fff;
      gap: 12px;
      font-weight: 600;
      padding: 8px 24px;
      cursor: pointer;
      background-color: #8358ff;
      border-radius: 9999px;
      &.loading {
        background-color: rgba(#8358ff, 0.6);
      }
    }
  }
}
