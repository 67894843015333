.login_layout {
  background-color: #f7f6fe;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo {
    /*padding-top: 32px;*/
    padding-bottom: 24px;
  }
  .form {
    background-color: #fff;
    box-shadow: 0 1rem 3rem rgba(48, 47, 58, 0.0875);
    padding: 40px;
    border-radius: 1rem;
    width: 340px;
    box-sizing: border-box;
    margin-bottom: 32px;
  }
  .field {
    input {
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
      font-size: 16px;
      line-height: 1.42857143;
      color: #555;
      background-color: #fff;
      background-image: none;
      border: 1px solid #ccc;
      border-radius: 4px;
      &:focus,
      &:focus-within {
        outline: none;
        /*background-color: rgba(241, 218, 211, 0.4);*/
        box-shadow: 0 0.3em 1.2em rgba(250, 108, 216, 0.3);
        border-color: rgb(229, 145, 220);
        border-width: 2px;
      }
    }
    input[type="email"] {
      margin-bottom: -1px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    input[type="password"] {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
    button {
      margin-top: 24px;
      width: 100%;
      line-height: 1.42857143;
    }
  }
  .forgot_password {
    margin-top: 24px;
    text-align: center;
    a,
    a:active,
    a:visited,
    a:focus {
      text-decoration: none;
      color: #606060; /* #7f0052;*/
    }
  }
  .separator {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #606060;
    padding: 20px;
    &.first_separator {
      padding-top: 0;
    }
  }
}
