@import "./shared/shared";
@import "./login/login.scss";
@import "./campaign/campaign.scss";
@import "./interview/interview.scss";

body {
  margin: 0;
  background-color: #f8f9fa !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  /*
    font-family: "Poppins",system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*
body {   
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}
*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

video#preview {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

textarea {
  resize: none;
}

hr {
  border-top: none;
  height: 1px;
  margin: 1rem 0;
  color: inherit;
  border: 0;
  opacity: 0.25;
}
hr.horizontal.dark {
  background-image: linear-gradient(
    90deg,
    transparent,
    rgba(0, 0, 0, 0.4),
    transparent
  );
  background-color: transparent;
}
.shadow {
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.12) !important;
}
.border-radius-lg {
  border-radius: 0.75rem;
}
.d-flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.text-sm {
  font-size: 0.875rem !important;
  line-height: 1.5;
}

.badge {
  --bs-badge-padding-x: 0.9em;
  --bs-badge-padding-y: 0.55em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.45rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius, 0);
}
.badge,
.btn {
  text-transform: uppercase;
}
.bg-gradient-success {
  background-image: linear-gradient(310deg, #17ad37, #98ec2d);
}
.bg-gradient-secondary {
  background-image: linear-gradient(310deg, #627594, #a8b8d8);
}

.bg-primary {
  background-color: #cb0c9f !important;
}

.bg-secondary {
  background-color: #8392ab !important;
}

.bg-success {
  background-color: #62d862 /* #82d616*/ !important;
}

.bg-info {
  background-color: #17c1e8 !important;
}

.bg-warning {
  background-color: #fbcf33 !important;
}

.bg-danger {
  background-color: #ea0606 !important;
}

.bg-light {
  background-color: #e9ecef !important;
}

.bg-dark {
  background-color: #344767 !important;
}

.bg-white {
  background-color: #fff !important;
}
// Badge
$badge-sm-padding: 0.45em 0.775em !default;
$badge-sm-font-size: 0.65em !default;
$badge-md-padding: 0.65em 1em !default;
$badge-lg-padding: 0.85em 1.375em !default;
$badge-inline-margin-right: 0.625rem !default;
$badge-inline-span-top: 2px !default;
$badge-btn-margin: 0.5rem !default;

// Badge Circle
$badge-circle-border-radius: 50% !default;
$badge-circle-width: 1.25rem !default;
$badge-circle-height: 1.25rem !default;
$badge-circle-font-size: 0.75rem !default;
$badge-circle-font-weight: 600 !default;

$badge-circle-md-width: 1.5rem !default;
$badge-circle-md-height: 1.5rem !default;

$badge-circle-lg-width: 2rem !default;
$badge-circle-lg-height: 2rem !default;

//Badge Dot
$badge-dot-icon-width: 0.375rem !default;
$badge-dot-icon-height: 0.375rem !default;
$badge-dot-icon-radius: 50% !default;
$badge-dot-icon-margin-right: 0.375rem !default;

$badge-dot-md-icon-width: 0.5rem !default;
$badge-dot-md-icon-height: 0.5rem !default;

$badge-dot-lg-icon-width: 0.625rem !default;
$badge-dot-lg-icon-height: 0.625rem !default;

//Badge Floating
$badge-floating-top: -50% !default;
$badge-floating-border: 3px !default;
$badge-floating-transform: translate(147%, 50%) !default;
$border-radius-xs: 0.125rem !default;
$border-radius-sm: 0.25rem !default;
$border-radius-md: 0.5rem !default;
$border-radius-lg: 0.75rem !default;
$border-radius-xl: 1rem !default;
$border-radius-2xl: 1.5rem !default;
$border-radius-section: 10rem !default;

.badge-sm {
  padding: $badge-sm-padding;
  font-size: $badge-sm-font-size;
  border-radius: $border-radius-sm;
}

.badge-md {
  padding: $badge-md-padding;
}

.badge-lg {
  padding: $badge-lg-padding;
}

.flex-center {
  display: flex;
  justify-content: center;
}
