.flow_waitlist_main {
  h1 {
    margin: 0;
    font-weight: 700 !important;
    font-size: 1rem;
    line-height: 1.625;
    color: #344767;
  }
  .body_content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;

    .content_header {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
  .waitlist_body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .message_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 320px;
  }
  .message_icon_wrapper {
    background-color: #f8f9fa;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .message_text {
    text-align: center;
    color: rgb(103, 116, 142);
    font-size: 1rem;
  }
  .message_button {
    a,
    a:focus,
    a:visited,
    a:active {
      text-decoration: none;
    }
  }
}
