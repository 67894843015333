.enter_key_dialog {
  .dialog_body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 32px;
    padding-bottom: 32px;

    .model_selector {
      padding-bottom: 32px;
      display: flex;
      gap: 24px;

      .model_item {
        display: flex;
        align-items: center;
        gap: 8px;
        border-radius: 0.75rem;
        background-color: #f8f9fa;
        padding: 12px 32px;
        cursor: pointer;

        &.selected {
          box-shadow: 0 0 0 3px rgba(131, 88, 255, 0.6);
          background-color: rgba(#8358ff, 0.1);
        }
      }
    }
    .api_key_help {
      color: rgb(52, 71, 103);
      a,
      a:focus,
      a:active,
      a:visited {
        color: rgb(52, 71, 103);
      }
    }
  }
  .buttons {
    column-gap: 12px;
  }
  .key_storage_notice {
    background-color: #fff3cd;
    border-radius: 6px;
    padding: 12px 16px;
    border: 1px solid #ffe69c;
    color: #664d02;
  }
}
