.create_train_step {
  display: flex;
  flex-direction: column;

  .widget_code_button_container {
    display: flex;
    gap: 16px;
    align-items: center;
    padding-top: 16px;
  }
  .indexing_status {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 1rem;
  }
}
