.team_content_panel {
  h2 {
    margin-top: 0;
    font-weight: 700 !important;
    font-size: 2rem;
    line-height: 1.625;
    color: #344767;
  }
  .panel_title {
    color: #344767;
    font-size: 1.17em;
    font-weight: 700;
    padding-bottom: 16px;
  }
  .actions_panel {
    margin-bottom: 24px;
    button:disabled {
      background-color: #eee;
    }
    .upgrade_notice {
      padding-top: 12px;
      color: rgb(221, 152, 93);
      a {
        color: rgb(138, 197, 111);
      }
    }
  }
}
.members_panel {
  .member {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
    background-color: #fff;
    border-radius: 3px;
    padding: 20px;

    @media (max-width: 767.98px) {
      & {
        display: flex;
        flex-direction: column;
        > div:not(:last-child) {
          margin-bottom: 16px;
        }
      }
    }
    @media (min-width: 768px) {
      & {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        align-items: center;
      }
    }

    .member_name {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .member_unteremail {
        font-size: 10pt;
        color: gray;
      }
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
    > div {
      display: flex;
      align-items: center;
      padding-right: 16px;

      &.role {
        display: unset !important;
      }
      &.action {
        justify-content: flex-end;
        min-height: 42px;
        svg {
          margin-right: 0;
        }
      }
      svg {
        margin-right: 6px;
      }
    }
  }
}
.invitations_panel {
  margin-bottom: 32px;

  .invitation {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
    background-color: #fff3cd;
    color: #664d03;
    border-radius: 3px;
    padding: 20px;

    @media (max-width: 767.98px) {
      & {
        display: flex;
        flex-direction: column;
        > div:not(:last-child) {
          margin-bottom: 16px;
        }
      }
    }
    @media (min-width: 768px) {
      & {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        column-gap: 12px;
        align-items: center;
      }
    }

    .inv_email {
      -webkit-user-select: all; /* Chrome 49+ */
      -moz-user-select: all; /* Firefox 43+ */
      -ms-user-select: all; /* No support yet */
      user-select: all; /* Likely future */
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
    div {
      display: flex;
      align-items: center;
      padding-right: 16px;
      svg {
        margin-right: 6px;
      }
    }
    .action {
      justify-content: flex-end;
    }
  }
}
.invite_member_dialog {
  .buttons {
    .app-btn-no-border {
      margin-left: 24px;
    }
  }
  .email_form {
    margin-top: 24px;
    padding-bottom: 12px;
    padding-left: 12px;
    position: relative;
    svg {
      position: absolute;
      left: 24px;
      top: 11px;

      @media (max-width: 767.98px) {
        & {
          left: 19px;
        }
      }
    }
    &.loading {
      input {
        color: #155724;
        background-color: #d4edda;
        &:focus,
        &:active {
          outline: none;
          box-shadow: 0 0 0 4px #c3e6cb;
        }
      }
    }
    input {
      border: unset;
      font-size: 16px;
      padding: 15px;
      padding-left: 50px;
      /*box-shadow: 0 3px 6px rgba(33,33,33,0.2);*/
      border-radius: 5px;
      background-color: rgb(240, 243, 244);
      width: 80%;

      @media (max-width: 767.98px) {
        & {
          padding-left: 40px;
        }
      }

      &:focus,
      &:active {
        outline: none;
        /*box-shadow: 0 3px 6px rgba(77,64,132,0.3);*/
        /*box-shadow: 0 0 0 4px rgba(77, 64, 132, 0.3);*/
        box-shadow: 0 0 0 4px rgba(#ff7171, 0.5);
      }
    }
  } // end of email_form
  .admin_role_checkbox {
    padding-left: 12px;
    padding-bottom: 24px;
    display: flex;
    align-items: center;
    input {
      margin-right: 12px;
    }
    span {
      cursor: pointer;
    }
  }
  .role_dropdown {
    padding-left: 12px;
    padding-bottom: 36px;
    display: flex;
    justify-content: flex-start;
  }
}
.delete_member_dialog {
  .dialog_text {
    margin-top: 32px;
    margin-bottom: 32px !important;
  }
  .buttons {
    .app-btn-no-border {
      margin-left: 24px;
    }
  }
}
