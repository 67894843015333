.hub_model_details {
  .breadcrumbs {
    display: flex;
    align-items: center;
    color: rgb(103, 116, 142);
    font-size: 0.875rem;
    padding-bottom: 8px;
    & > div {
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 6px;
      flex-basis: fit-content;
    }
  }
  .model_name {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 2rem;
    line-height: 1.375;
    color: #344767;
    column-gap: 12px;
    margin-top: 0px;
    margin-bottom: 12px;
  }
  .model_id {
    color: #67748e;
    font-weight: 500;
    font-size: 1.2rem;
    padding-bottom: 32px;
  }
  .model_api_console_card {
    margin-bottom: 32px;
    .api_console_title {
      font-weight: 500 !important;
      font-size: 1.4rem;
      line-height: 1.625;
      color: #344767;
      margin-top: -12px;
    }
    .api_console_help {
      color: rgb(103, 116, 142);
      font-size: 1rem;
      padding-top: 12px;
    }
    .tab_list {
      margin-top: 42px;
      border-bottom: 1px solid #d9d9d9;
      .tab_item {
        display: inline-flex;
        align-items: center;
        gap: 8px;
        padding: 12px 24px;
        color: #344767;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.5;
        &.selected {
          background-color: rgb(244, 245, 245);
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
      }
    }
  }
}
