.widget_chat_message {
  position: relative;
  display: flex;
  gap: 12px;
  /*box-shadow: 0 0.3em 1em hsla(0, 0%, 80%, 0.6);*/

  padding: 12px;
  border-radius: 6px;

  &.user {
    background-color: rgb(248, 249, 250);
  }
  &.assistant {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
    background-color: #fff;
  }

  .user_avatar {
    display: flex;
    padding: 8px;
    border-radius: 6px;
    /*background-color: #efeff3;*/
    border: 1px solid #dee2e6;
  }
  .openai_avatar {
    display: flex;
    padding: 8px;
    border-radius: 6px;
    background-color: rgb(117, 169, 155);
  }
  .message_item {
    flex-grow: 1;
    max-width: 100%;
    color: #344767;
    line-height: 1.6rem;

    h1,
    h2,
    h3,
    h4 {
      line-height: 1.2;
    }

    /*box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05); */
    /*  box-shadow: 0 0.3em 1.2em hsla(0, 0%, 80%, 0.6); */
    & > pre > div {
      border-radius: 6px;
      max-width: 100%;
      width: 100%;
    }
    & > * {
      max-width: 90%;
      width: 90%;
      margin-top: 0;
      margin-bottom: 24px;
    }
    &.user {
      background-color: rgb(248, 249, 250);
    }
    textarea {
      padding: 16px 24px;
      background-color: #fff;
    }
    .editing_container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: 0;
    }
    .edit_actions {
      display: flex;
      gap: 16px;
      .btn {
        margin-bottom: 0;
      }
    }
  }

  .message_metadata {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    align-items: center;
    height: 36px;
    padding-right: 14px;

    .message_metadata_item {
      display: flex;
      gap: 6px;
      align-items: center;
      font-size: 0.8rem;
      color: rgb(109, 123, 147);
    }
  }

  .message_cover {
    position: absolute;
    /*top: 0;
    left: 0;*/
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 16px;

    .message_actions {
      display: flex;
      background-color: rgba(255, 255, 255, 0.6);
      /*box-shadow: 0 4px 12px rgba(#8358ff, 0.12);*/
      background-color: rgba(253, 218, 203, 0.4);
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      padding: 12px;
      gap: 6px;
      button.btn {
        position: relative;
        margin-bottom: 0 !important;
        padding: 0.5rem 1rem !important;
      }
    }
  }
  .confirm_container {
    position: relative;
    cursor: pointer;
    .cover {
      position: fixed;
      inset: 0;
      z-index: 998;
    }
    .confirm_panel {
      position: absolute;
      z-index: 99999;
      top: 12px;
      right: -38px;
      padding: 12px 14px;
      background-color: #fff;
      border: 1px solid #b8b8b8;
      border-radius: 5px;

      button {
        color: #fff;
        padding: 0.5rem 2rem;
        margin: 0;
      }
    }
  }
}
