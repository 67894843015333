.shared_form_result_page {
  display: flex;
  min-height: 100%;

  .form_row {
    grid-column-start: 1;
    grid-column-end: 3;
    display: flex;
    flex-direction: column;

    h1 {
      text-align: center;
      color: #344767;
      width: 100%;
    }
    .form_description {
      text-align: center;
      color: #344767;
      width: 100%;
    }
    .form_body {
      display: flex;
      flex-direction: column;

      label {
        text-align: center;
        color: rgb(103, 116, 142);
        padding-bottom: 0;
      }
    }
    .form_actions {
      display: flex;
      justify-content: center;
    }
    .between {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 24px;
    }
    .form_fields_container {
      display: flex;
      flex-direction: column;
      padding-top: 24px;
      gap: 24px;
    }
    .preview_form_output {
      display: flex;
      flex-direction: column;
      padding-top: 16px;
    }
    .response_placeholder_container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 160px;
      background-color: rgba(255, 255, 255, 1);
      color: rgb(103, 116, 142);
      border-radius: 10px;
      border-radius: 10px;
      box-shadow: 0px 0px 35px 5px rgba(0, 0, 0, 0.05) inset;
      /*0px 0px 35px 5px rgba(0, 0, 0, 0.25) inset;*/
      /* 0px 2px 1px 1px rgba(255, 255, 255, 0.9) inset; */
    }
    .response_label {
      text-align: center;
      color: rgb(103, 116, 142);
      padding-bottom: 12px;
    }
    .choice_container {
      padding: 16px;
      border: 1px solid #d2d6da;
      border-radius: 4px;
      background-color: #f8f9fa;
      .choice {
        white-space: pre-wrap;
        font-size: 1rem;
      }
    }
  }
  .share_button_wrapper {
    position: relative;
  }
  .share_button_container {
    position: absolute;
    right: -20px;
    top: -36px;
  }

  .share_container {
    position: relative;
    cursor: pointer;
    .cover {
      position: fixed;
      inset: 0;
    }
    .confirm_panel {
      position: absolute;
      width: 300px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      z-index: 999;
      top: -16px;
      right: 20px;
      padding: 12px 14px;
      background-color: #fff;
      /*border: 1px solid #b8b8b8;*/
      box-shadow: 0 0 16px 16px rgb(0 0 0 / 5%);
      border-radius: 5px;

      .share_form_button {
        padding: 0.5rem 2rem;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .share_bottom_button_container {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 24px;
    .btn-outline-secondary {
      margin-bottom: 0;
    }
  }
  .share_bottom_container {
    position: relative;
    cursor: pointer;
    .cover {
      position: fixed;
      inset: 0;
    }
    .confirm_panel {
      position: absolute;
      width: 300px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      z-index: 999;
      bottom: 56px;
      left: -272px;
      padding: 12px 14px;
      background-color: #fff;
      /*border: 1px solid #b8b8b8;*/
      box-shadow: 0 0 16px 16px rgb(0 0 0 / 5%);
      border-radius: 5px;

      .share_form_button {
        padding: 0.5rem 2rem;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
