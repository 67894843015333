.pitchground_register_page {
  background-color: #f7f6fe;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .container {
    box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    background-clip: border-box;
    border-radius: 1rem;
    padding: 1.5rem;
    width: 340px;

    .header {
      text-align: center;

      color: #344767;
      font-size: 1.25rem;
      line-height: 1.375;
      h5 {
        margin-top: 0;
        margin-bottom: 12px;
      }
      .subtitle {
        font-size: 1rem;
        padding-bottom: 24px;
      }
    }
    .terms {
      display: flex;
      align-items: center;
      font-size: 0.875rem;
      font-weight: 400;

      a,
      a:active,
      a:focus,
      a:visited {
        color: rgb(19, 23, 64); /* #344767; */
        text-decoration: none;
        letter-spacing: -0.025rem;
        font-weight: 700;
      }
      input[type="checkbox"]:checked::after {
        opacity: 1;
      }
      input[type="checkbox"]::after {
        transition: opacity 0.25s ease-in-out;

        content: "✔";
        width: 100%;
        height: 100%;
        color: #fff;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.67rem;
        opacity: 0;
      }
      input[type="checkbox"]:checked {
        border: 0;
        background-image: linear-gradient(
          310deg,
          rgb(131, 88, 255),
          rgb(131, 88, 255)
        );
      }
      input[type="checkbox"] {
        border: 1px solid #cbd3da;
        margin-right: 0.75rem;
        position: relative;
        cursor: pointer;
        border-radius: 0.35rem;

        appearance: none;
        width: 1.23em;
        height: 1.23em;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: contain;
        print-color-adjust: exact;
        transition: background-color 0.25s ease, border-color 0.25s ease,
          background-position 0.15s ease-in-out, opacity 0.15s ease-out,
          box-shadow 0.15s ease-in-out;
      }
    }
  }

  .logo {
    /*padding-top: 32px;*/
    padding-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
  }
  .form > form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .field {
    input {
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
      font-size: 16px;
      line-height: 1.42857143;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #d2d6da;
      border-radius: 0.5rem;
      appearance: none;
      transition: box-shadow 0.15s ease, border-color 0.15s ease;
      background-image: none;

      &:focus,
      &:focus-within {
        outline: none;
        /*background-color: rgba(241, 218, 211, 0.4);*/
        box-shadow: 0 0.3em 1.2em rgba(250, 108, 216, 0.3);
        border-color: rgb(229, 145, 220);
        border-width: 2px;
      }
    }

    button.btn {
      margin-top: 24px;
      width: 100%;
      line-height: 1.42857143;
      background-color: rgb(131, 88, 255);
      border-radius: 9999px;
      color: #fff;
      justify-content: center;
      --tw-shadow: 5px 5px 10px rgba(108, 106, 213, 0.25),
        inset 2px 2px 6px #a78df0, inset -5px -5px 10px #6336e4;
      --tw-shadow-colored: 5px 5px 10px var(--tw-shadow-color),
        inset 2px 2px 6px var(--tw-shadow-color),
        inset -5px -5px 10px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }
  }
  .forgot_password {
    margin-top: 24px;
    text-align: center;
    a,
    a:active,
    a:visited,
    a:focus {
      text-decoration: none;
      color: #606060; /* #7f0052;*/
    }
  }
  .separator {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #606060;
    padding: 20px;
    &.first_separator {
      padding-top: 0;
    }
  }
}
