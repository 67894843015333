.create_chat_dialog {
  & > div {
    width: 640px;
  }
  .dialog_body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 32px;
    padding-bottom: 32px;

    .model_selector {
      padding-bottom: 32px;
      display: flex;
      gap: 24px;
      flex-wrap: wrap;
      justify-content: center;

      .model_item {
        display: flex;
        align-items: center;
        gap: 8px;
        border-radius: 0.75rem;
        background-color: #f8f9fa;
        padding: 12px;
        cursor: pointer;
        min-width: 268px;

        &.selected {
          box-shadow: 0 0 0 3px rgba(131, 88, 255, 0.6);
          background-color: rgba(#8358ff, 0.1);
        }
        .logo {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 12px;
        }
        .name_column {
          display: flex;
          flex-direction: column;
          gap: 4px;
          .name {
            color: rgb(52, 71, 103);
            font-weight: 600;
            font-size: 1rem;
          }
          .context {
            color: rgb(103, 116, 142);
            font-size: 0.875rem;
            font-weight: 600;
            line-height: 1.5;
            letter-spacing: 0.02857em;
          }
        }
      }
    }
  }
  .buttons {
    column-gap: 12px;
  }
  .key_storage_notice {
    background-color: #fff3cd;
    border-radius: 6px;
    padding: 12px 16px;
    border: 1px solid #ffe69c;
    color: #664d02;
  }
}
