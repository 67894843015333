.recording_preview_container {
    position: absolute;
    top: 16px;
    right: 16px;
    
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;

    &.dragged {
        cursor: -webkit-grabbing;
        cursor:    -moz-grabbing;
        cursor:         grabbing;
    }

    video {
        border-radius: 0.5rem;
    }
}