/*
.btn {
    --bs-btn-padding-x: 0.875rem;
    --bs-btn-padding-y: 0.545rem;
    --bs-btn-font-family: ;
    --bs-btn-font-size: 1rem;
    --bs-btn-font-weight: 500;
    --bs-btn-line-height: 1.6;
    --bs-btn-color: #25252F;
    --bs-btn-bg: transparent;
    --bs-btn-border-width: 2px;
    --bs-btn-border-color: transparent;
    --bs-btn-border-radius: 0.5rem;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(21, 21, 29, 0.075);
    --bs-btn-disabled-opacity: 0.65;
    --bs-btn-focus-box-shadow: 0 0 0 0.125rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
    display: inline-block;
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
    font-family: var(--bs-btn-font-family);
    font-size: var(--bs-btn-font-size);
    font-weight: var(--bs-btn-font-weight);
    line-height: var(--bs-btn-line-height);
    color: var(--bs-btn-color);
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
    border-radius: var(--bs-btn-border-radius);
    background-color: var(--bs-btn-bg);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, transform 0.2s;
  }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none;
    }
  }
  :not(.btn-check) + .btn:hover, .btn:first-child:hover {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
  }
  .btn:focus-visible {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow);
  }
  .btn-check:focus-visible + .btn {
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow);
  }
  .btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color);
  }
  .btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
    box-shadow: var(--bs-btn-focus-box-shadow);
  }
  .btn:disabled, .btn.disabled, fieldset:disabled .btn {
    color: var(--bs-btn-disabled-color);
    pointer-events: none;
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
    opacity: var(--bs-btn-disabled-opacity);
  }
  
  .btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #644EF3;
    --bs-btn-border-color: #644EF3;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #5542cf;
    --bs-btn-hover-border-color: #503ec2;
    --bs-btn-focus-shadow-rgb: 123, 105, 245;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #503ec2;
    --bs-btn-active-border-color: #4b3bb6;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(21, 21, 29, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #644EF3;
    --bs-btn-disabled-border-color: #644EF3;
  }
  
  .btn-secondary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #514F5E;
    --bs-btn-border-color: #514F5E;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #454350;
    --bs-btn-hover-border-color: #413f4b;
    --bs-btn-focus-shadow-rgb: 107, 105, 118;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #413f4b;
    --bs-btn-active-border-color: #3d3b47;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(21, 21, 29, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #514F5E;
    --bs-btn-disabled-border-color: #514F5E;
  }
  
  .btn-success {
    --bs-btn-color: #15151D;
    --bs-btn-bg: #5BDF91;
    --bs-btn-border-color: #5BDF91;
    --bs-btn-hover-color: #15151D;
    --bs-btn-hover-bg: #74e4a2;
    --bs-btn-hover-border-color: #6be29c;
    --bs-btn-focus-shadow-rgb: 81, 193, 128;
    --bs-btn-active-color: #15151D;
    --bs-btn-active-bg: #7ce5a7;
    --bs-btn-active-border-color: #6be29c;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(21, 21, 29, 0.125);
    --bs-btn-disabled-color: #15151D;
    --bs-btn-disabled-bg: #5BDF91;
    --bs-btn-disabled-border-color: #5BDF91;
  }
  
  .btn-info {
    --bs-btn-color: #15151D;
    --bs-btn-bg: #1bb3f7;
    --bs-btn-border-color: #1bb3f7;
    --bs-btn-hover-color: #15151D;
    --bs-btn-hover-bg: #3dbef8;
    --bs-btn-hover-border-color: #32bbf8;
    --bs-btn-focus-shadow-rgb: 26, 155, 214;
    --bs-btn-active-color: #15151D;
    --bs-btn-active-bg: #49c2f9;
    --bs-btn-active-border-color: #32bbf8;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(21, 21, 29, 0.125);
    --bs-btn-disabled-color: #15151D;
    --bs-btn-disabled-bg: #1bb3f7;
    --bs-btn-disabled-border-color: #1bb3f7;
  }
  
  .btn-warning {
    --bs-btn-color: #fff;
    --bs-btn-bg: #f9662e;
    --bs-btn-border-color: #f9662e;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #d45727;
    --bs-btn-hover-border-color: #c75225;
    --bs-btn-focus-shadow-rgb: 250, 125, 77;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #c75225;
    --bs-btn-active-border-color: #bb4d23;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(21, 21, 29, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #f9662e;
    --bs-btn-disabled-border-color: #f9662e;
  }
  
  .btn-danger {
    --bs-btn-color: #15151D;
    --bs-btn-bg: #F96D80;
    --bs-btn-border-color: #F96D80;
    --bs-btn-hover-color: #15151D;
    --bs-btn-hover-bg: #fa8393;
    --bs-btn-hover-border-color: #fa7c8d;
    --bs-btn-focus-shadow-rgb: 215, 96, 113;
    --bs-btn-active-color: #15151D;
    --bs-btn-active-bg: #fa8a99;
    --bs-btn-active-border-color: #fa7c8d;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(21, 21, 29, 0.125);
    --bs-btn-disabled-color: #15151D;
    --bs-btn-disabled-bg: #F96D80;
    --bs-btn-disabled-border-color: #F96D80;
  }
  
  .btn-light {
    --bs-btn-color: #15151D;
    --bs-btn-bg: #F7F6FF;
    --bs-btn-border-color: #F7F6FF;
    --bs-btn-hover-color: #15151D;
    --bs-btn-hover-bg: #d2d1d9;
    --bs-btn-hover-border-color: #c6c5cc;
    --bs-btn-focus-shadow-rgb: 213, 212, 221;
    --bs-btn-active-color: #15151D;
    --bs-btn-active-bg: #c6c5cc;
    --bs-btn-active-border-color: #b9b9bf;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(21, 21, 29, 0.125);
    --bs-btn-disabled-color: #15151D;
    --bs-btn-disabled-bg: #F7F6FF;
    --bs-btn-disabled-border-color: #F7F6FF;
  }
  
  .btn-white {
    --bs-btn-color: #15151D;
    --bs-btn-bg: #fff;
    --bs-btn-border-color: #fff;
    --bs-btn-hover-color: #15151D;
    --bs-btn-hover-bg: white;
    --bs-btn-hover-border-color: white;
    --bs-btn-focus-shadow-rgb: 220, 220, 221;
    --bs-btn-active-color: #15151D;
    --bs-btn-active-bg: white;
    --bs-btn-active-border-color: white;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(21, 21, 29, 0.125);
    --bs-btn-disabled-color: #15151D;
    --bs-btn-disabled-bg: #fff;
    --bs-btn-disabled-border-color: #fff;
  }
  
  .btn-dark {
    --bs-btn-color: #fff;
    --bs-btn-bg: #15151D;
    --bs-btn-border-color: #15151D;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #38383f;
    --bs-btn-hover-border-color: #2c2c34;
    --bs-btn-focus-shadow-rgb: 56, 56, 63;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #44444a;
    --bs-btn-active-border-color: #2c2c34;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(21, 21, 29, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #15151D;
    --bs-btn-disabled-border-color: #15151D;
  }
  
  .btn-outline-primary {
    --bs-btn-color: #644EF3;
    --bs-btn-border-color: #644EF3;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #644EF3;
    --bs-btn-hover-border-color: #644EF3;
    --bs-btn-focus-shadow-rgb: 100, 78, 243;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #644EF3;
    --bs-btn-active-border-color: #644EF3;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(21, 21, 29, 0.125);
    --bs-btn-disabled-color: #644EF3;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #644EF3;
    --bs-gradient: none;
  }
  
  .btn-outline-secondary {
    --bs-btn-color: #514F5E;
    --bs-btn-border-color: #514F5E;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #514F5E;
    --bs-btn-hover-border-color: #514F5E;
    --bs-btn-focus-shadow-rgb: 81, 79, 94;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #514F5E;
    --bs-btn-active-border-color: #514F5E;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(21, 21, 29, 0.125);
    --bs-btn-disabled-color: #514F5E;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #514F5E;
    --bs-gradient: none;
  }
  
  .btn-outline-success {
    --bs-btn-color: #5BDF91;
    --bs-btn-border-color: #5BDF91;
    --bs-btn-hover-color: #15151D;
    --bs-btn-hover-bg: #5BDF91;
    --bs-btn-hover-border-color: #5BDF91;
    --bs-btn-focus-shadow-rgb: 91, 223, 145;
    --bs-btn-active-color: #15151D;
    --bs-btn-active-bg: #5BDF91;
    --bs-btn-active-border-color: #5BDF91;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(21, 21, 29, 0.125);
    --bs-btn-disabled-color: #5BDF91;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #5BDF91;
    --bs-gradient: none;
  }
  
  .btn-outline-info {
    --bs-btn-color: #1bb3f7;
    --bs-btn-border-color: #1bb3f7;
    --bs-btn-hover-color: #15151D;
    --bs-btn-hover-bg: #1bb3f7;
    --bs-btn-hover-border-color: #1bb3f7;
    --bs-btn-focus-shadow-rgb: 27, 179, 247;
    --bs-btn-active-color: #15151D;
    --bs-btn-active-bg: #1bb3f7;
    --bs-btn-active-border-color: #1bb3f7;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(21, 21, 29, 0.125);
    --bs-btn-disabled-color: #1bb3f7;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #1bb3f7;
    --bs-gradient: none;
  }
  
  .btn-outline-warning {
    --bs-btn-color: #f9662e;
    --bs-btn-border-color: #f9662e;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #f9662e;
    --bs-btn-hover-border-color: #f9662e;
    --bs-btn-focus-shadow-rgb: 249, 102, 46;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #f9662e;
    --bs-btn-active-border-color: #f9662e;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(21, 21, 29, 0.125);
    --bs-btn-disabled-color: #f9662e;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #f9662e;
    --bs-gradient: none;
  }
  
  .btn-outline-danger {
    --bs-btn-color: #F96D80;
    --bs-btn-border-color: #F96D80;
    --bs-btn-hover-color: #15151D;
    --bs-btn-hover-bg: #F96D80;
    --bs-btn-hover-border-color: #F96D80;
    --bs-btn-focus-shadow-rgb: 249, 109, 128;
    --bs-btn-active-color: #15151D;
    --bs-btn-active-bg: #F96D80;
    --bs-btn-active-border-color: #F96D80;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(21, 21, 29, 0.125);
    --bs-btn-disabled-color: #F96D80;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #F96D80;
    --bs-gradient: none;
  }
  
  .btn-outline-light {
    --bs-btn-color: #F7F6FF;
    --bs-btn-border-color: #F7F6FF;
    --bs-btn-hover-color: #15151D;
    --bs-btn-hover-bg: #F7F6FF;
    --bs-btn-hover-border-color: #F7F6FF;
    --bs-btn-focus-shadow-rgb: 247, 246, 255;
    --bs-btn-active-color: #15151D;
    --bs-btn-active-bg: #F7F6FF;
    --bs-btn-active-border-color: #F7F6FF;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(21, 21, 29, 0.125);
    --bs-btn-disabled-color: #F7F6FF;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #F7F6FF;
    --bs-gradient: none;
  }
  
  .btn-outline-white {
    --bs-btn-color: #fff;
    --bs-btn-border-color: #fff;
    --bs-btn-hover-color: #15151D;
    --bs-btn-hover-bg: #fff;
    --bs-btn-hover-border-color: #fff;
    --bs-btn-focus-shadow-rgb: 255, 255, 255;
    --bs-btn-active-color: #15151D;
    --bs-btn-active-bg: #fff;
    --bs-btn-active-border-color: #fff;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(21, 21, 29, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #fff;
    --bs-gradient: none;
  }
  
  .btn-outline-dark {
    --bs-btn-color: #15151D;
    --bs-btn-border-color: #15151D;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #15151D;
    --bs-btn-hover-border-color: #15151D;
    --bs-btn-focus-shadow-rgb: 21, 21, 29;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #15151D;
    --bs-btn-active-border-color: #15151D;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(21, 21, 29, 0.125);
    --bs-btn-disabled-color: #15151D;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #15151D;
    --bs-gradient: none;
  }
  
  .btn-link {
    --bs-btn-font-weight: 400;
    --bs-btn-color: var(--bs-link-color);
    --bs-btn-bg: transparent;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: var(--bs-link-hover-color);
    --bs-btn-hover-border-color: transparent;
    --bs-btn-active-color: var(--bs-link-hover-color);
    --bs-btn-active-border-color: transparent;
    --bs-btn-disabled-color: #8B8A99;
    --bs-btn-disabled-border-color: transparent;
    --bs-btn-box-shadow: none;
    --bs-btn-focus-shadow-rgb: 123, 105, 245;
    text-decoration: none;
  }
  .btn-link:focus-visible {
    color: var(--bs-btn-color);
  }
  .btn-link:hover {
    color: var(--bs-btn-hover-color);
  }
  
  .btn-lg, .btn-group-lg > .btn {
    --bs-btn-padding-y: 0.875rem;
    --bs-btn-padding-x: 1.625rem;
    --bs-btn-font-size: 1.125rem;
    --bs-btn-border-radius: 0.675rem;
  }
  
  .btn-sm, .btn-group-sm > .btn {
    --bs-btn-padding-y: 0.375rem;
    --bs-btn-padding-x: 0.675rem;
    --bs-btn-font-size: 0.875rem;
    --bs-btn-border-radius: 0.35rem;
  }
  
  .fade {
    transition: opacity 0.15s linear;
  }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none;
    }
  }
  .fade:not(.show) {
    opacity: 0;
  }
  
  .collapse:not(.show) {
    display: none;
  }
  
  .collapsing {
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none;
    }
  }
  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .collapsing.collapse-horizontal {
      transition: none;
    }
  }
*/
.no-btn {
  display: inline-flex;
  align-items: center;
  border: none;
  padding: 0;
  margin: 0;
  box-shadow: none !important;
  text-decoration: none;
  background: unset;
  color: unset;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  &:hover,
  &:active,
  &:focus {
    background: unset;
    outline: unset;
  }
}

.btn {
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-padding-y: 0.75rem;
  --bs-btn-font-size: 0.75rem;
  --bs-btn-font-weight: 700;
  --bs-btn-line-height: 1.4;
  --bs-btn-color: #67748e;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.5rem;
  --bs-btn-box-shadow: 0 4px 7px -1px rgba(0, 0, 0, 0.11),
    0 2px 4px -1px rgba(0, 0, 0, 0.07);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.2rem
    rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: all 0.15s ease-in;
}

.btn {
  display: inline-block;
  font-weight: 700;
  line-height: 1.4;
  color: #67748e;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.75rem 1.5rem;
  font-size: 0.75rem;
  border-radius: 0.5rem;
  transition: all 0.15s ease-in;
  margin-bottom: 1rem;
  letter-spacing: -0.025rem;
  box-shadow: 0 4px 7px -1px rgb(0 0 0 / 11%), 0 2px 4px -1px rgb(0 0 0 / 7%);
  background-size: 150%;
  background-position-x: 25%;
}

.btn {
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
}

.btn:hover {
  color: #67748e;
}

.btn:hover:not(.btn-icon-only) {
  box-shadow: 0 3px 5px -1px rgb(0 0 0 / 9%), 0 2px 3px -1px rgb(0 0 0 / 7%);
  transform: scale(1.02);
}

.btn-primary:hover,
.btn.bg-gradient-primary:hover {
  background-color: #cb0c9f;
  border-color: #cb0c9f;
}

.btn-primary .btn.bg-outline-primary,
.btn.bg-gradient-primary .btn.bg-outline-primary {
  border: 1px solid #cb0c9f;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle,
.btn.bg-gradient-primary:not(:disabled):not(.disabled).active,
.btn.bg-gradient-primary:not(:disabled):not(.disabled):active,
.show > .btn.bg-gradient-primary.dropdown-toggle {
  color: color-yiq(#cb0c9f);
  background-color: #cb0c9f;
}

.btn-primary.focus,
.btn-primary:focus,
.btn.bg-gradient-primary.focus,
.btn.bg-gradient-primary:focus {
  color: #fff;
}

.btn {
  margin-bottom: 1rem;
  letter-spacing: -0.025rem;
  text-transform: uppercase;
  box-shadow: 0 4px 7px -1px rgba(0, 0, 0, 0.11),
    0 2px 4px -1px rgba(0, 0, 0, 0.07);
  background-size: 150%;
  background-position-x: 25%;
}

.btn:not([class*="btn-outline-"]) {
  border: 0;
}

.btn:active,
.btn:active:focus,
.btn:active:hover {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.09),
    0 2px 3px -1px rgba(0, 0, 0, 0.07);
  transform: scale(1);
  opacity: 0.85;
}

.btn:hover:not(.btn-icon-only) {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.09),
    0 2px 3px -1px rgba(0, 0, 0, 0.07);
  transform: scale(1.02);
}

.btn.bg-white:hover {
  color: #67748e;
}

.btn.btn-link {
  box-shadow: none;
  font-weight: 700;
}

.btn.btn-link:hover,
.btn.btn-link:focus {
  box-shadow: none;
}

.btn.btn-round {
  border-radius: 1.875rem;
}

.btn.btn-icon-only {
  width: 2.375rem;
  height: 2.375rem;
  padding: 0.7rem 0.7rem;
}

.btn.btn-sm.btn-icon-only,
.btn-group-sm > .btn.btn-icon-only {
  width: 1.5875rem;
  height: 1.5875rem;
  padding: 0.3rem 0.3rem;
}

.btn.btn-sm i,
.btn-group-sm > .btn i {
  font-size: 0.5rem;
}

.btn.btn-lg.btn-icon-only,
.btn-group-lg > .btn.btn-icon-only {
  width: 3.25rem;
  height: 3.25rem;
  padding: 1rem 1rem;
}

.btn.btn-lg i,
.btn-group-lg > .btn i {
  font-size: 1.2rem;
  position: relative;
  top: 2px;
}

.btn.btn-rounded {
  border-radius: 1.875rem;
}

.btn-check:checked + .btn svg .color-background {
  fill: #fff;
}

.btn-check:checked + .btn:hover svg .color-background {
  fill: #344767;
}

.icon-move-right i {
  transition: all 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3);
}

.icon-move-right:hover i,
.icon-move-right:focus i {
  transform: translateX(5px);
}

.icon-move-left i {
  transition: all 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3);
}

.icon-move-left:hover i,
.icon-move-left:focus i {
  transform: translateX(-5px);
}

.btn-primary:hover,
.btn.bg-gradient-primary:hover {
  background-color: #cb0c9f;
  border-color: #cb0c9f;
}

.btn-primary .btn.bg-outline-primary,
.btn.bg-gradient-primary .btn.bg-outline-primary {
  border: 1px solid #cb0c9f;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle,
.btn.bg-gradient-primary:not(:disabled):not(.disabled).active,
.btn.bg-gradient-primary:not(:disabled):not(.disabled):active,
.show > .btn.bg-gradient-primary.dropdown-toggle {
  color: color-yiq(#cb0c9f);
  background-color: #cb0c9f;
}

.btn-primary.focus,
.btn-primary:focus,
.btn.bg-gradient-primary.focus,
.btn.bg-gradient-primary:focus {
  color: #fff;
}

.btn-outline-primary {
  box-shadow: none;
}

.btn-outline-primary:hover:not(.active) {
  background-color: transparent;
  opacity: 0.75;
  box-shadow: none;
  color: #cb0c9f;
}

.btn-secondary:hover,
.btn.bg-gradient-secondary:hover {
  background-color: #8392ab;
  border-color: #8392ab;
}

.btn-secondary .btn.bg-outline-secondary,
.btn.bg-gradient-secondary .btn.bg-outline-secondary {
  border: 1px solid #8392ab;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle,
.btn.bg-gradient-secondary:not(:disabled):not(.disabled).active,
.btn.bg-gradient-secondary:not(:disabled):not(.disabled):active,
.show > .btn.bg-gradient-secondary.dropdown-toggle {
  color: color-yiq(#8392ab);
  background-color: #8392ab;
}

.btn-secondary.focus,
.btn-secondary:focus,
.btn.bg-gradient-secondary.focus,
.btn.bg-gradient-secondary:focus {
  color: #fff;
}

.btn-outline-secondary {
  box-shadow: none;
}

.btn-outline-secondary:hover:not(.active) {
  background-color: transparent;
  opacity: 0.75;
  box-shadow: none;
  color: #8392ab;
}

.btn-success:hover,
.btn.bg-gradient-success:hover {
  background-color: #82d616;
  border-color: #82d616;
}

.btn-success .btn.bg-outline-success,
.btn.bg-gradient-success .btn.bg-outline-success {
  border: 1px solid #82d616;
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle,
.btn.bg-gradient-success:not(:disabled):not(.disabled).active,
.btn.bg-gradient-success:not(:disabled):not(.disabled):active,
.show > .btn.bg-gradient-success.dropdown-toggle {
  color: color-yiq(#82d616);
  background-color: #82d616;
}

.btn-success.focus,
.btn-success:focus,
.btn.bg-gradient-success.focus,
.btn.bg-gradient-success:focus {
  color: #fff;
}

.btn-outline-success {
  box-shadow: none;
}

.btn-outline-success:hover:not(.active) {
  background-color: transparent;
  opacity: 0.75;
  box-shadow: none;
  color: #82d616;
}

.btn-info:hover,
.btn.bg-gradient-info:hover {
  background-color: #17c1e8;
  border-color: #17c1e8;
}

.btn-info .btn.bg-outline-info,
.btn.bg-gradient-info .btn.bg-outline-info {
  border: 1px solid #17c1e8;
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle,
.btn.bg-gradient-info:not(:disabled):not(.disabled).active,
.btn.bg-gradient-info:not(:disabled):not(.disabled):active,
.show > .btn.bg-gradient-info.dropdown-toggle {
  color: color-yiq(#17c1e8);
  background-color: #17c1e8;
}

.btn-info.focus,
.btn-info:focus,
.btn.bg-gradient-info.focus,
.btn.bg-gradient-info:focus {
  color: #fff;
}

.btn-outline-info {
  box-shadow: none;
}

.btn-outline-info:hover:not(.active) {
  background-color: transparent;
  opacity: 0.75;
  box-shadow: none;
  color: #17c1e8;
}

.btn-warning:hover,
.btn.bg-gradient-warning:hover {
  background-color: #fbcf33;
  border-color: #fbcf33;
}

.btn-warning .btn.bg-outline-warning,
.btn.bg-gradient-warning .btn.bg-outline-warning {
  border: 1px solid #fbcf33;
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle,
.btn.bg-gradient-warning:not(:disabled):not(.disabled).active,
.btn.bg-gradient-warning:not(:disabled):not(.disabled):active,
.show > .btn.bg-gradient-warning.dropdown-toggle {
  color: color-yiq(#fbcf33);
  background-color: #fbcf33;
}

.btn-warning.focus,
.btn-warning:focus,
.btn.bg-gradient-warning.focus,
.btn.bg-gradient-warning:focus {
  color: #fff;
}

.btn-outline-warning {
  box-shadow: none;
}

.btn-outline-warning:hover:not(.active) {
  background-color: transparent;
  opacity: 0.75;
  box-shadow: none;
  color: #fbcf33;
}

.btn-danger:hover,
.btn.bg-gradient-danger:hover {
  background-color: #ea0606;
  border-color: #ea0606;
}

.btn-danger .btn.bg-outline-danger,
.btn.bg-gradient-danger .btn.bg-outline-danger {
  border: 1px solid #ea0606;
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle,
.btn.bg-gradient-danger:not(:disabled):not(.disabled).active,
.btn.bg-gradient-danger:not(:disabled):not(.disabled):active,
.show > .btn.bg-gradient-danger.dropdown-toggle {
  color: color-yiq(#ea0606);
  background-color: #ea0606;
}

.btn-danger.focus,
.btn-danger:focus,
.btn.bg-gradient-danger.focus,
.btn.bg-gradient-danger:focus {
  color: #fff;
}

.btn-outline-danger {
  box-shadow: none;
}

.btn-outline-danger:hover:not(.active) {
  background-color: transparent;
  opacity: 0.75;
  box-shadow: none;
  color: #ea0606;
}

.btn-light:hover,
.btn.bg-gradient-light:hover {
  background-color: #e9ecef;
  border-color: #e9ecef;
}

.btn-light .btn.bg-outline-light,
.btn.bg-gradient-light .btn.bg-outline-light {
  border: 1px solid #e9ecef;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle,
.btn.bg-gradient-light:not(:disabled):not(.disabled).active,
.btn.bg-gradient-light:not(:disabled):not(.disabled):active,
.show > .btn.bg-gradient-light.dropdown-toggle {
  color: color-yiq(#e9ecef);
  background-color: #e9ecef;
}

.btn-outline-light {
  box-shadow: none;
}

.btn-outline-light:hover:not(.active) {
  background-color: transparent;
  opacity: 0.75;
  box-shadow: none;
  color: #e9ecef;
}

.btn-dark:hover,
.btn.bg-gradient-dark:hover {
  background-color: #344767;
  border-color: #344767;
}

.btn-dark .btn.bg-outline-dark,
.btn.bg-gradient-dark .btn.bg-outline-dark {
  border: 1px solid #344767;
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show > .btn-dark.dropdown-toggle,
.btn.bg-gradient-dark:not(:disabled):not(.disabled).active,
.btn.bg-gradient-dark:not(:disabled):not(.disabled):active,
.show > .btn.bg-gradient-dark.dropdown-toggle {
  color: color-yiq(#344767);
  background-color: #344767;
}

.btn-dark.focus,
.btn-dark:focus,
.btn.bg-gradient-dark.focus,
.btn.bg-gradient-dark:focus {
  color: #fff;
}

.btn-outline-dark {
  box-shadow: none;
}

.btn-outline-dark:hover:not(.active) {
  background-color: transparent;
  opacity: 0.75;
  box-shadow: none;
  color: #344767;
}

.btn-white:hover,
.btn.bg-gradient-white:hover {
  background-color: #fff;
  border-color: #fff;
}

.btn-white .btn.bg-outline-white,
.btn.bg-gradient-white .btn.bg-outline-white {
  border: 1px solid #fff;
}

.btn-white:not(:disabled):not(.disabled).active,
.btn-white:not(:disabled):not(.disabled):active,
.show > .btn-white.dropdown-toggle,
.btn.bg-gradient-white:not(:disabled):not(.disabled).active,
.btn.bg-gradient-white:not(:disabled):not(.disabled):active,
.show > .btn.bg-gradient-white.dropdown-toggle {
  color: color-yiq(#fff);
  background-color: #fff;
}

.btn-outline-white {
  box-shadow: none;
}

.btn-outline-white:hover:not(.active) {
  background-color: transparent;
  opacity: 0.75;
  box-shadow: none;
  color: #fff;
}

.btn-outline-white {
  border-color: rgba(255, 255, 255, 0.75);
  background: rgba(255, 255, 255, 0.1);
}

.btn-primary,
.btn.bg-gradient-primary {
  color: #fff;
}

.btn-primary:hover,
.btn.bg-gradient-primary:hover {
  color: #fff;
}

.btn-secondary,
.btn.bg-gradient-secondary {
  color: #fff;
}

.btn-secondary:hover,
.btn.bg-gradient-secondary:hover {
  color: #fff;
}

.btn-danger,
.btn.bg-gradient-danger {
  color: #fff;
}

.btn-danger:hover,
.btn.bg-gradient-danger:hover {
  color: #fff;
}

.btn-info,
.btn.bg-gradient-info {
  color: #fff;
}

.btn-info:hover,
.btn.bg-gradient-info:hover {
  color: #fff;
}

.btn-success,
.btn.bg-gradient-success {
  color: #fff;
}

.btn-success:hover,
.btn.bg-gradient-success:hover {
  color: #fff;
}

.btn-warning,
.btn.bg-gradient-warning {
  color: #fff;
}

.btn-warning:hover,
.btn.bg-gradient-warning:hover {
  color: #fff;
}

.btn-dark,
.btn.bg-gradient-dark {
  color: #fff;
}

.btn-dark:hover,
.btn.bg-gradient-dark:hover {
  color: #fff;
}

.btn-light,
.btn.bg-gradient-light {
  color: #3a416f;
}

.btn-light:hover,
.btn.bg-gradient-light:hover {
  color: #3a416f;
}

.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.09),
    0 2px 3px -1px rgba(0, 0, 0, 0.07);
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #fff;
  background-color: #cb0c9f;
  border-color: #cb0c9f;
}

.btn-primary:hover {
  color: #fff;
  background-color: #ad0a87;
  border-color: #a20a7f;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: #ad0a87;
  border-color: #a20a7f;
  box-shadow: 0 0 0 0.2rem rgba(211, 48, 173, 0.5);
}

.btn-check:checked + .btn-primary,
.btn-check:active + .btn-primary,
.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #a20a7f;
  border-color: #980977;
}

.btn-check:checked + .btn-primary:focus,
.btn-check:active + .btn-primary:focus,
.btn-primary:active:focus,
.btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 48, 173, 0.5);
}

.btn-primary:disabled,
.btn-primary.disabled {
  color: #fff;
  background-color: #cb0c9f;
  border-color: #cb0c9f;
}

.btn-secondary {
  color: #000;
  background-color: #8392ab;
  border-color: #8392ab;
}

.btn-secondary:hover {
  color: #000;
  background-color: #96a2b8;
  border-color: #8f9db3;
}

.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  color: #000;
  background-color: #96a2b8;
  border-color: #8f9db3;
  box-shadow: 0 0 0 0.2rem rgba(111, 124, 145, 0.5);
}

.btn-check:checked + .btn-secondary,
.btn-check:active + .btn-secondary,
.btn-secondary:active,
.btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  color: #000;
  background-color: #9ca8bc;
  border-color: #8f9db3;
}

.btn-check:checked + .btn-secondary:focus,
.btn-check:active + .btn-secondary:focus,
.btn-secondary:active:focus,
.btn-secondary.active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(111, 124, 145, 0.5);
}

.btn-secondary:disabled,
.btn-secondary.disabled {
  color: #000;
  background-color: #8392ab;
  border-color: #8392ab;
}

.btn-success {
  color: #000;
  background-color: #82d616;
  border-color: #82d616;
}

.btn-success:hover {
  color: #000;
  background-color: #95dc39;
  border-color: #8fda2d;
}

.btn-check:focus + .btn-success,
.btn-success:focus {
  color: #000;
  background-color: #95dc39;
  border-color: #8fda2d;
  box-shadow: 0 0 0 0.2rem rgba(111, 182, 19, 0.5);
}

.btn-check:checked + .btn-success,
.btn-check:active + .btn-success,
.btn-success:active,
.btn-success.active,
.show > .btn-success.dropdown-toggle {
  color: #000;
  background-color: #9bde45;
  border-color: #8fda2d;
}

.btn-check:checked + .btn-success:focus,
.btn-check:active + .btn-success:focus,
.btn-success:active:focus,
.btn-success.active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(111, 182, 19, 0.5);
}

.btn-success:disabled,
.btn-success.disabled {
  color: #000;
  background-color: #82d616;
  border-color: #82d616;
}

.btn-info {
  color: #000;
  background-color: #17c1e8;
  border-color: #17c1e8;
}

.btn-info:hover {
  color: #000;
  background-color: #3acaeb;
  border-color: #2ec7ea;
}

.btn-check:focus + .btn-info,
.btn-info:focus {
  color: #000;
  background-color: #3acaeb;
  border-color: #2ec7ea;
  box-shadow: 0 0 0 0.2rem rgba(20, 164, 197, 0.5);
}

.btn-check:checked + .btn-info,
.btn-check:active + .btn-info,
.btn-info:active,
.btn-info.active,
.show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #45cded;
  border-color: #2ec7ea;
}

.btn-check:checked + .btn-info:focus,
.btn-check:active + .btn-info:focus,
.btn-info:active:focus,
.btn-info.active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(20, 164, 197, 0.5);
}

.btn-info:disabled,
.btn-info.disabled {
  color: #000;
  background-color: #17c1e8;
  border-color: #17c1e8;
}

.btn-warning {
  color: #000;
  background-color: #fbcf33;
  border-color: #fbcf33;
}

.btn-warning:hover {
  color: #000;
  background-color: #fcd652;
  border-color: #fbd447;
}

.btn-check:focus + .btn-warning,
.btn-warning:focus {
  color: #000;
  background-color: #fcd652;
  border-color: #fbd447;
  box-shadow: 0 0 0 0.2rem rgba(213, 176, 43, 0.5);
}

.btn-check:checked + .btn-warning,
.btn-check:active + .btn-warning,
.btn-warning:active,
.btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #fcd95c;
  border-color: #fbd447;
}

.btn-check:checked + .btn-warning:focus,
.btn-check:active + .btn-warning:focus,
.btn-warning:active:focus,
.btn-warning.active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(213, 176, 43, 0.5);
}

.btn-warning:disabled,
.btn-warning.disabled {
  color: #000;
  background-color: #fbcf33;
  border-color: #fbcf33;
}

.btn-danger {
  color: #fff;
  background-color: #ea0606;
  border-color: #ea0606;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c70505;
  border-color: #bb0505;
}

.btn-check:focus + .btn-danger,
.btn-danger:focus {
  color: #fff;
  background-color: #c70505;
  border-color: #bb0505;
  box-shadow: 0 0 0 0.2rem rgba(237, 43, 43, 0.5);
}

.btn-check:checked + .btn-danger,
.btn-check:active + .btn-danger,
.btn-danger:active,
.btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bb0505;
  border-color: #b00505;
}

.btn-check:checked + .btn-danger:focus,
.btn-check:active + .btn-danger:focus,
.btn-danger:active:focus,
.btn-danger.active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(237, 43, 43, 0.5);
}

.btn-danger:disabled,
.btn-danger.disabled {
  color: #fff;
  background-color: #ea0606;
  border-color: #ea0606;
}

.btn-light {
  color: #000;
  background-color: #e9ecef;
  border-color: #e9ecef;
}

.btn-light:hover {
  color: #000;
  background-color: #eceff1;
  border-color: #ebeef1;
}

.btn-check:focus + .btn-light,
.btn-light:focus {
  color: #000;
  background-color: #eceff1;
  border-color: #ebeef1;
  box-shadow: 0 0 0 0.2rem rgba(198, 201, 203, 0.5);
}

.btn-check:checked + .btn-light,
.btn-check:active + .btn-light,
.btn-light:active,
.btn-light.active,
.show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #edf0f2;
  border-color: #ebeef1;
}

.btn-check:checked + .btn-light:focus,
.btn-check:active + .btn-light:focus,
.btn-light:active:focus,
.btn-light.active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(198, 201, 203, 0.5);
}

.btn-light:disabled,
.btn-light.disabled {
  color: #000;
  background-color: #e9ecef;
  border-color: #e9ecef;
}

.btn-dark {
  color: #fff;
  background-color: #344767;
  border-color: #344767;
}

.btn-dark:hover {
  color: #fff;
  background-color: #2c3c58;
  border-color: #2a3952;
}

.btn-check:focus + .btn-dark,
.btn-dark:focus {
  color: #fff;
  background-color: #2c3c58;
  border-color: #2a3952;
  box-shadow: 0 0 0 0.2rem rgba(82, 99, 126, 0.5);
}

.btn-check:checked + .btn-dark,
.btn-check:active + .btn-dark,
.btn-dark:active,
.btn-dark.active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #2a3952;
  border-color: #27354d;
}

.btn-check:checked + .btn-dark:focus,
.btn-check:active + .btn-dark:focus,
.btn-dark:active:focus,
.btn-dark.active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 99, 126, 0.5);
}

.btn-dark:disabled,
.btn-dark.disabled {
  color: #fff;
  background-color: #344767;
  border-color: #344767;
}

.btn-white {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.btn-white:hover {
  color: #000;
  background-color: white;
  border-color: white;
}

.btn-check:focus + .btn-white,
.btn-white:focus {
  color: #000;
  background-color: white;
  border-color: white;
  box-shadow: 0 0 0 0.2rem rgba(217, 217, 217, 0.5);
}

.btn-check:checked + .btn-white,
.btn-check:active + .btn-white,
.btn-white:active,
.btn-white.active,
.show > .btn-white.dropdown-toggle {
  color: #000;
  background-color: white;
  border-color: white;
}

.btn-check:checked + .btn-white:focus,
.btn-check:active + .btn-white:focus,
.btn-white:active:focus,
.btn-white.active:focus,
.show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 217, 217, 0.5);
}

.btn-white:disabled,
.btn-white.disabled {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-primary {
  color: #cb0c9f;
  border-color: #cb0c9f;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #cb0c9f;
  border-color: #cb0c9f;
}

.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(203, 12, 159, 0.5);
}

.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #cb0c9f;
  border-color: #cb0c9f;
}

.btn-check:checked + .btn-outline-primary:focus,
.btn-check:active + .btn-outline-primary:focus,
.btn-outline-primary:active:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(203, 12, 159, 0.5);
}

.btn-outline-primary:disabled,
.btn-outline-primary.disabled {
  color: #cb0c9f;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #8392ab;
  border-color: #8392ab;
}

.btn-outline-secondary:hover {
  color: #000;
  background-color: #8392ab;
  border-color: #8392ab;
}

.btn-check:focus + .btn-outline-secondary,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(131, 146, 171, 0.5);
}

.btn-check:checked + .btn-outline-secondary,
.btn-check:active + .btn-outline-secondary,
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show {
  color: #000;
  background-color: #8392ab;
  border-color: #8392ab;
}

.btn-check:checked + .btn-outline-secondary:focus,
.btn-check:active + .btn-outline-secondary:focus,
.btn-outline-secondary:active:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(131, 146, 171, 0.5);
}

.btn-outline-secondary:disabled,
.btn-outline-secondary.disabled {
  color: #8392ab;
  background-color: transparent;
}

.btn-outline-success {
  color: #82d616;
  border-color: #82d616;
}

.btn-outline-success:hover {
  color: #000;
  background-color: #82d616;
  border-color: #82d616;
}

.btn-check:focus + .btn-outline-success,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 214, 22, 0.5);
}

.btn-check:checked + .btn-outline-success,
.btn-check:active + .btn-outline-success,
.btn-outline-success:active,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show {
  color: #000;
  background-color: #82d616;
  border-color: #82d616;
}

.btn-check:checked + .btn-outline-success:focus,
.btn-check:active + .btn-outline-success:focus,
.btn-outline-success:active:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 214, 22, 0.5);
}

.btn-outline-success:disabled,
.btn-outline-success.disabled {
  color: #82d616;
  background-color: transparent;
}

.btn-outline-info {
  color: #17c1e8;
  border-color: #17c1e8;
}

.btn-outline-info:hover {
  color: #000;
  background-color: #17c1e8;
  border-color: #17c1e8;
}

.btn-check:focus + .btn-outline-info,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 193, 232, 0.5);
}

.btn-check:checked + .btn-outline-info,
.btn-check:active + .btn-outline-info,
.btn-outline-info:active,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #17c1e8;
  border-color: #17c1e8;
}

.btn-check:checked + .btn-outline-info:focus,
.btn-check:active + .btn-outline-info:focus,
.btn-outline-info:active:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 193, 232, 0.5);
}

.btn-outline-info:disabled,
.btn-outline-info.disabled {
  color: #17c1e8;
  background-color: transparent;
}

.btn-outline-warning {
  color: #fbcf33;
  border-color: #fbcf33;
}

.btn-outline-warning:hover {
  color: #000;
  background-color: #fbcf33;
  border-color: #fbcf33;
}

.btn-check:focus + .btn-outline-warning,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(251, 207, 51, 0.5);
}

.btn-check:checked + .btn-outline-warning,
.btn-check:active + .btn-outline-warning,
.btn-outline-warning:active,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #fbcf33;
  border-color: #fbcf33;
}

.btn-check:checked + .btn-outline-warning:focus,
.btn-check:active + .btn-outline-warning:focus,
.btn-outline-warning:active:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(251, 207, 51, 0.5);
}

.btn-outline-warning:disabled,
.btn-outline-warning.disabled {
  color: #fbcf33;
  background-color: transparent;
}

.btn-outline-danger {
  color: #ea0606;
  border-color: #ea0606;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #ea0606;
  border-color: #ea0606;
}

.btn-check:focus + .btn-outline-danger,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(234, 6, 6, 0.5);
}

.btn-check:checked + .btn-outline-danger,
.btn-check:active + .btn-outline-danger,
.btn-outline-danger:active,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #ea0606;
  border-color: #ea0606;
}

.btn-check:checked + .btn-outline-danger:focus,
.btn-check:active + .btn-outline-danger:focus,
.btn-outline-danger:active:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(234, 6, 6, 0.5);
}

.btn-outline-danger:disabled,
.btn-outline-danger.disabled {
  color: #ea0606;
  background-color: transparent;
}

.btn-outline-light {
  color: #e9ecef;
  border-color: #e9ecef;
}

.btn-outline-light:hover {
  color: #000;
  background-color: #e9ecef;
  border-color: #e9ecef;
}

.btn-check:focus + .btn-outline-light,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5);
}

.btn-check:checked + .btn-outline-light,
.btn-check:active + .btn-outline-light,
.btn-outline-light:active,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #e9ecef;
  border-color: #e9ecef;
}

.btn-check:checked + .btn-outline-light:focus,
.btn-check:active + .btn-outline-light:focus,
.btn-outline-light:active:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5);
}

.btn-outline-light:disabled,
.btn-outline-light.disabled {
  color: #e9ecef;
  background-color: transparent;
}

.btn-outline-dark {
  color: #344767;
  border-color: #344767;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #344767;
  border-color: #344767;
}

.btn-check:focus + .btn-outline-dark,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 71, 103, 0.5);
}

.btn-check:checked + .btn-outline-dark,
.btn-check:active + .btn-outline-dark,
.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #344767;
  border-color: #344767;
}

.btn-check:checked + .btn-outline-dark:focus,
.btn-check:active + .btn-outline-dark:focus,
.btn-outline-dark:active:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 71, 103, 0.5);
}

.btn-outline-dark:disabled,
.btn-outline-dark.disabled {
  color: #344767;
  background-color: transparent;
}

.btn-outline-white {
  color: #fff;
  border-color: #fff;
}

.btn-outline-white:hover {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.btn-check:focus + .btn-outline-white,
.btn-outline-white:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-check:checked + .btn-outline-white,
.btn-check:active + .btn-outline-white,
.btn-outline-white:active,
.btn-outline-white.active,
.btn-outline-white.dropdown-toggle.show {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.btn-check:checked + .btn-outline-white:focus,
.btn-check:active + .btn-outline-white:focus,
.btn-outline-white:active:focus,
.btn-outline-white.active:focus,
.btn-outline-white.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-white:disabled,
.btn-outline-white.disabled {
  color: #fff;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #cb0c9f;
  text-decoration: none;
}

.btn-link:hover {
  color: #830866;
  text-decoration: none;
}

.btn-link:focus {
  text-decoration: none;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 0.875rem 4rem;
  font-size: 0.875rem;
  border-radius: 0.5rem;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.5rem 2rem;
  font-size: 0.75rem;
  border-radius: 0.5rem;
}

.btn-primary:hover,
.btn.bg-gradient-primary:hover {
  background-color: #cb0c9f;
  border-color: #cb0c9f;
}

.btn-primary .btn.bg-outline-primary,
.btn.bg-gradient-primary .btn.bg-outline-primary {
  border: 1px solid #cb0c9f;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle,
.btn.bg-gradient-primary:not(:disabled):not(.disabled).active,
.btn.bg-gradient-primary:not(:disabled):not(.disabled):active,
.show > .btn.bg-gradient-primary.dropdown-toggle {
  color: color-yiq(#cb0c9f);
  background-color: #cb0c9f;
}

.btn-primary.focus,
.btn-primary:focus,
.btn.bg-gradient-primary.focus,
.btn.bg-gradient-primary:focus {
  color: #fff;
}

.bg-gradient-primary {
  background-image: linear-gradient(310deg, #7928ca 0%, #ff0080 100%);
}

.bg-gradient-secondary {
  background-image: linear-gradient(310deg, #627594 0%, #a8b8d8 100%);
}

.bg-gradient-success {
  background-image: linear-gradient(310deg, #17ad37 0%, #98ec2d 100%);
}

.bg-gradient-info {
  background-image: linear-gradient(310deg, #2152ff 0%, #21d4fd 100%);
}

.bg-gradient-warning {
  background-image: linear-gradient(310deg, #f53939 0%, #fbcf33 100%);
}

.bg-gradient-danger {
  background-image: linear-gradient(310deg, #ea0606 0%, #ff667c 100%);
}

.bg-gradient-light {
  background-image: linear-gradient(310deg, #ced4da 0%, #ebeff4 100%);
}

.bg-gradient-dark {
  background-image: linear-gradient(310deg, #141727 0%, #3a416f 100%);
}

.bg-gradient-faded-primary {
  background-image: radial-gradient(
    370px circle at 80% 50%,
    rgba(203, 12, 159, 0.6) 0,
    #9b0979 100%
  );
}

.bg-gradient-faded-secondary {
  background-image: radial-gradient(
    370px circle at 80% 50%,
    rgba(131, 146, 171, 0.6) 0,
    #657796 100%
  );
}

.bg-gradient-faded-success {
  background-image: radial-gradient(
    370px circle at 80% 50%,
    rgba(130, 214, 22, 0.6) 0,
    #66a811 100%
  );
}

.bg-gradient-faded-info {
  background-image: radial-gradient(
    370px circle at 80% 50%,
    rgba(23, 193, 232, 0.6) 0,
    #129aba 100%
  );
}

.bg-gradient-faded-warning {
  background-image: radial-gradient(
    370px circle at 80% 50%,
    rgba(251, 207, 51, 0.6) 0,
    #f6c105 100%
  );
}

.bg-gradient-faded-danger {
  background-image: radial-gradient(
    370px circle at 80% 50%,
    rgba(234, 6, 6, 0.6) 0,
    #b80505 100%
  );
}

.bg-gradient-faded-light {
  background-image: radial-gradient(
    370px circle at 80% 50%,
    rgba(233, 236, 239, 0.6) 0,
    #cbd3da 100%
  );
}

.bg-gradient-faded-dark {
  background-image: radial-gradient(
    370px circle at 80% 50%,
    rgba(52, 71, 103, 0.6) 0,
    #233045 100%
  );
}

.bg-gradient-faded-white {
  background-image: radial-gradient(
    370px circle at 80% 50%,
    rgba(255, 255, 255, 0.6) 0,
    #e6e6e6 100%
  );
}

.bg-gradient-faded-primary-vertical {
  background-image: radial-gradient(
    200px circle at 50% 70%,
    rgba(203, 12, 159, 0.3) 0,
    #cb0c9f 100%
  );
}

.bg-gradient-faded-secondary-vertical {
  background-image: radial-gradient(
    200px circle at 50% 70%,
    rgba(131, 146, 171, 0.3) 0,
    #8392ab 100%
  );
}

.bg-gradient-faded-success-vertical {
  background-image: radial-gradient(
    200px circle at 50% 70%,
    rgba(130, 214, 22, 0.3) 0,
    #82d616 100%
  );
}

.bg-gradient-faded-info-vertical {
  background-image: radial-gradient(
    200px circle at 50% 70%,
    rgba(23, 193, 232, 0.3) 0,
    #17c1e8 100%
  );
}

.bg-gradient-faded-warning-vertical {
  background-image: radial-gradient(
    200px circle at 50% 70%,
    rgba(251, 207, 51, 0.3) 0,
    #fbcf33 100%
  );
}

.bg-gradient-faded-danger-vertical {
  background-image: radial-gradient(
    200px circle at 50% 70%,
    rgba(234, 6, 6, 0.3) 0,
    #ea0606 100%
  );
}

.bg-gradient-faded-light-vertical {
  background-image: radial-gradient(
    200px circle at 50% 70%,
    rgba(233, 236, 239, 0.3) 0,
    #e9ecef 100%
  );
}

.bg-gradient-faded-dark-vertical {
  background-image: radial-gradient(
    200px circle at 50% 70%,
    rgba(52, 71, 103, 0.3) 0,
    #344767 100%
  );
}

.bg-gradient-faded-white-vertical {
  background-image: radial-gradient(
    200px circle at 50% 70%,
    rgba(255, 255, 255, 0.3) 0,
    #fff 100%
  );
}

.btn-primary:hover,
.btn.bg-gradient-primary:hover {
  background-color: #cb0c9f;
  border-color: #cb0c9f;
}

.btn-primary .btn.bg-outline-primary,
.btn.bg-gradient-primary .btn.bg-outline-primary {
  border: 1px solid #cb0c9f;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle,
.btn.bg-gradient-primary:not(:disabled):not(.disabled).active,
.btn.bg-gradient-primary:not(:disabled):not(.disabled):active,
.show > .btn.bg-gradient-primary.dropdown-toggle {
  color: color-yiq(#cb0c9f);
  background-color: #cb0c9f;
}

.btn-primary.focus,
.btn-primary:focus,
.btn.bg-gradient-primary.focus,
.btn.bg-gradient-primary:focus {
  color: #fff;
}

.btn:not([class*="btn-outline-"]) {
  border: 0;
}

/* 3D button */

.bg-accent {
  --tw-bg-opacity: 1;
  background-color: rgb(131 88 255 / var(--tw-bg-opacity));
}

.bg-accent-dark:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(116 68 255 / var(--tw-bg-opacity));
  cursor: pointer;
}

.rounded-full {
  border-radius: 9999px;
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

.shadow-accent-volume,
.shadow-white-volume {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-accent-volume {
  --tw-shadow: 5px 5px 10px rgba(108, 106, 213, 0.25), inset 2px 2px 6px #a78df0,
    inset -5px -5px 10px #6336e4;
  --tw-shadow-colored: 5px 5px 10px var(--tw-shadow-color),
    inset 2px 2px 6px var(--tw-shadow-color),
    inset -5px -5px 10px var(--tw-shadow-color);
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.font-semibold {
  font-weight: 600;
}

.btn-3d {
  font-size: 1.125rem;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
}

.text-center {
  text-align: center;
}

*,
:after,
:before {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-scroll-snap-strictness: proximity;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 transparent;
  --tw-ring-shadow: 0 0 transparent;
  --tw-shadow: 0 0 transparent;
  --tw-shadow-colored: 0 0 transparent;
}
