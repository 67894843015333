.form-control {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d2d6da;
  appearance: none;
  border-radius: 0.5rem;
  transition: box-shadow 0.15s ease, border-color 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: rgba(131, 88, 255, 0.6);
  outline: 0;
  box-shadow: 0 0 0 2px rgba(131, 88, 255, 0.6);
}

.form-control::-webkit-date-and-time-value {
  height: 1.4rem;
}

.form-control::placeholder {
  color: #adb5bd;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.form-control::file-selector-button {
  padding: 0.5rem 0.75rem;
  margin: -0.5rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #495057;
  background-color: #fff;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: all 0.15s ease-in;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #f2f2f2;
}

.form-control::-webkit-file-upload-button {
  padding: 0.5rem 0.75rem;
  margin: -0.5rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #495057;
  background-color: #fff;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: all 0.15s ease-in;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #f2f2f2;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.5rem 0;
  margin-bottom: 0;
  line-height: 1.4rem;
  color: #344767;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: unset;
  padding: 0.25rem 0.75rem;
  font-size: 0.75rem;
  border-radius: 0.5rem;
}

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.75rem;
  margin: -0.25rem -0.75rem;
  margin-inline-end: 0.75rem;
}

.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.75rem;
  margin: -0.25rem -0.75rem;
  margin-inline-end: 0.75rem;
}

.form-control-lg {
  min-height: unset;
  padding: 0.75rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 0.5rem;
}

.form-control-lg::file-selector-button {
  padding: 0.75rem 0.75rem;
  margin: -0.75rem -0.75rem;
  margin-inline-end: 0.75rem;
}

.form-control-lg::-webkit-file-upload-button {
  padding: 0.75rem 0.75rem;
  margin: -0.75rem -0.75rem;
  margin-inline-end: 0.75rem;
}

textarea.form-control {
  min-height: unset;
}

textarea.form-control-sm {
  min-height: unset;
}

textarea.form-control-lg {
  min-height: unset;
}

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.5rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  height: 1.4rem;
  border-radius: 0.5rem;
}

.form-control-color::-webkit-color-swatch {
  height: 1.4rem;
  border-radius: 0.5rem;
}
