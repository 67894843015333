.create_chat_dialog {
  .dialog_body_row {
    display: flex;
    flex-direction: column;
    width: 100%;

    label,
    .model_label {
      padding-left: 6px;
      padding-bottom: 6px;
      color: rgb(103, 116, 142);
      font-weight: 400;
      font-size: 0.875rem;
    }
  }
}
