.chats_tab {
  .chats_area_container {
    display: flex;
    flex-grow: 1;
    padding-top: 16px;
  }
  .chat_list {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 380px;
    min-width: 380px;
    border: 1px solid #d2d6da;
    border-right: none;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    background-color: rgb(248, 249, 250);
    height: 100%;

    .chat_list_wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;

      display: flex;
      flex-direction: column;

      &:first-child {
        border-top-left-radius: 12px;
      }
      &:last-child {
        border-bottom-left-radius: 12px;
      }
    }

    .chat_item {
      cursor: pointer;
      padding: 18px 20px;
      border-right: 1px solid #d2d6da;
      border-bottom: 1px solid #d2d6da;
      &.selected {
        border-right: none;
        background-color: #fff;
      }
      .item_user_first_message {
        color: #344767;
        font-weight: 500;
        font-size: 1rem;
      }
      .item_time {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 6px;
        text-align: right;
        color: rgb(113, 113, 122);
        font-size: 14px;
        padding-top: 6px;
      }
    }
    .space_filler {
      flex-grow: 1;
      border-right: 1px solid #d2d6da;
    }
  }
}
