.trial_exired_dialog {
  .trial_exired_dialog_body {
    padding: 32px;

    .expired_message {
      color: #842029;
      background-color: #f8d7da;
      border-color: #f5c2c7;
      padding: 1rem 1rem;
      border: 1px solid #f5c2c7;
      border-radius: 0.25rem;
    }
  }
}
