.chatbot_widget_page {
  display: flex;
  flex-wrap: wrap;
  /*
  display: grid;
  grid-template-columns: 1fr 1fr;
  */
  gap: 16px;
  height: 100%;

  border-radius: 6px;
  border: 1px solid #ededed;

  .chat_area {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    .messages_container {
      flex-grow: 1;
      display: flex;
      position: relative;
      background-color: #fff;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;

      .messages_container_wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
        display: flex;
        justify-content: center;
        padding-bottom: 16px;
      }
    }
  }
  .messages_feed {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 16px;

    padding: 8px;
  }

  .artifact_area {
    display: flex;
    flex-direction: column;
  }
}
