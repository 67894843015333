@import "./interview_submission.scss";
@import "./recording.scss";

.interview_layout {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding: 48px;
}
.interview_layout_body {
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    width: 100%;
}