
.spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
  
    & .path {
      stroke: hsl(210, 70, 75);
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
  }
  
  .spinner_react_picture {
    animation: rotate 2s linear infinite;
    z-index: 2;
  
    & .path {
      stroke: #ff7171;
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
  }
  
  .spinner_container {
    padding: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
  
  .spinner-container {
    padding: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .spinner-container-no-padding {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /***** /Spinner *****/