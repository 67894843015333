
.start_interview_help {
    padding: 24px 48px 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 24px;

    .help_icon {

    }
    .help_text {
        color: #344767;
        text-align: center;
    }
}

.start_interview_content {
    display: flex;
    align-items: center;

    h1 {
        text-align: center;
        color: #344767;
    }
    .action_container {
        display: flex;
        justify-content: center;

        .btn {
            padding: 0.75rem 2.5rem;
        }
    } 
    .start_form {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        width: 300px;
        padding-bottom: 24px;
    }
    .hw_test_container {
        button.btn {
            margin-bottom: 0;
        }
        .test_hint {
            text-align: center;
            padding-top: 8px;
            font-size: 14px;
            color: rgb(103, 116, 142);
            padding-bottom: 24px;
        }
    }
    .hw_test_instructions {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 16px;
        padding-bottom: 24px;

        .hw_text {
            font-size: 1rem;
            font-weight: 600;
            color: #344767;
        }
        .hw_image {
            img {
                max-width: 500px;
                border-radius: .75rem;
                box-shadow: 0 .3125rem .625rem 0 rgba(0,0,0,.12)!important; 
            }
            
        }
    }
    .step_data_input {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 16px;
        padding-bottom: 24px;
        .data_text {
            font-size: 1rem;
            font-weight: 600;
            color: #344767;
        }
    }
}

.do_interview_content {    
    display: flex;
    height: 100%;
    h1 {
        text-align: center;
    }
    .question {
        margin-top: 24px;
        display: flex;
        flex-direction: column;        
        border-radius: 0.75rem;
        background-color: #f8f9fa;
        color: #333;
        padding: 24px;
        margin-right: 2rem;
        margin-left: 2rem;
        row-gap: 24px;
        .question_video {
            display: flex;
            justify-content: center;
        }
        .question_content {
            font-size: 16px;
            font-weight: bold;
            display: flex;
            justify-content: center;
        }
    }
    .question_button {
        margin-top: 48px;
        display: flex;
        justify-content: center;
        margin-right: 2rem;
        margin-left: 2rem;
    }
    .finished_message_container {
        display: flex;
        justify-content: center;
        padding-top: 24px;
    }
    .finished_message {
        display: flex;
        align-items: center;
        flex-direction: column;
        row-gap: 16px;
        text-align: center;
        padding: 24px;
        width: 300px;
        border-radius: 0.5rem;
        color: #fff;
    }
}