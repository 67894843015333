.metadata_container {
  display: flex;
  flex-direction: column;

  background-color: #f8f9fa;
  border-radius: 0.75rem;
}
.metadata_tile {
  display: flex;
  align-items: center;

  column-gap: 16px;
  padding: 12px;

  .metadata_prop {
    display: flex;
    gap: 6px;
    align-items: center;
  }
  input {
    width: 100%;
  }
  .btn {
    margin-bottom: 0 !important;
  }
}
