.prompt_list_page {
  padding: 24px 24px 24px 48px !important;
  h1 {
    margin: 0;
    font-weight: 700 !important;
    font-size: 2rem;
    line-height: 1.625;
    color: #344767;
    padding-bottom: 16px;
  }

  .actions_panel {
    padding-bottom: 16px;
  }
  .body_content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
  }
}
