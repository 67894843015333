.first_message {
  display: flex;
  gap: 24px;
  padding: 24px;
  border-radius: 6px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
  background-color: rgb(248, 249, 250);

  .avatar_wrapper {
    display: flex;
    padding: 8px;
    border-radius: 6px;

    border: 1px solid #dee2e6;
  }
  .message_body {
    flex-grow: 1;
    color: #344767;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;

    .system_prompt {
      font-size: 0.8rem;
      color: rgb(109, 123, 147);
    }
  }
  .message_actions {
    display: flex;
    align-items: center;
    .btn {
      margin-bottom: 0;
    }
  }
}
