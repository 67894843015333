.workspace_settings {
  h2 {
    font-weight: 700 !important;
    font-size: 1.6rem;
    line-height: 1.625;
    color: #344767;
  }
  h3 {
    color: #344767;
  }
  .settings_panel {
    box-shadow: 0 0.3em 1.2em rgba(204, 204, 204, 0.6);
    background-color: #fff;
    border-radius: 16px;
    padding: 40px;
    color: rgb(103, 116, 142);
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
  input.origin-control {
    margin-bottom: 12px;
    padding: 3px 10px;
    border-radius: 5px;
    background-color: #fff;
    color: rgb(51, 51, 51);
    font-size: 16px;
    width: 320px;
    height: 30px;
    border: 1px solid rgb(211, 211, 211);

    &:focus,
    &:active {
      outline: none;
      /*box-shadow: 0 3px 6px rgba(77,64,132,0.3);*/
      box-shadow: 0 0 0 2px rgba(77, 64, 132, 0.3);
    }
  }

  .confirm_container {
    position: relative;
    z-index: 10000;
    .cover {
      position: fixed;
      inset: 0;
    }
    .confirm_panel {
      position: absolute;
      z-index: 10000;
      top: -10px;
      left: -4px;
      padding: 12px 14px;
      background-color: #fff;
      border: 1px solid #b8b8b8;
      border-radius: 5px;

      button {
        margin-bottom: 0 !important;
        &.app-btn-red.app-btn {
          font-size: calc(14px * 1 / var(--de-scale));
          padding: 6px 12px;
          border-radius: 4px;
        }
      }
    }
  }

  .api_key_panel {
    box-shadow: 0 0.3em 1.2em rgba(204, 204, 204, 0.6);
    background-color: #fff;
    border-radius: 16px;
    padding: 40px;
    margin-top: 24px;
    margin-bottom: 24px;
    .api_key {
      background-color: #f0f3f4;
      color: rgb(124, 124, 124);
      padding: 9px 12px;
      border-radius: 4px;
      user-select: all;
      margin-bottom: 24px;
    }
    .help {
      padding-bottom: 16px;
      a,
      a:visited,
      a:hover,
      a:active {
        text-decoration: none;
        color: #660047;
      }
    }
  }
}
