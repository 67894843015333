.space_selector {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  padding: 20px 30px;
  border-top: 2px solid rgba(255, 255, 255, 0.5);
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);

  @media (max-width: 767.98px) {
    & {
      margin-bottom: 0px;
    }
  }

  & > div:first-child {
    width: 100%;
    display: flex;
    align-items: center;
    img {
      height: 28px;
      margin-right: 15px;
      border-radius: 3px;
    }
    svg {
      width: 24px;
    }
    svg:first-child {
      margin-right: 15px;
    }
    svg:nth-child(2) {
    }
    .widen {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .current {
    cursor: pointer;
  }
  .options {
    background-color: rgba(255, 255, 255, 0.7);
    color: #383838;
    border-radius: 8px;
    padding: 15px;
    margin-top: 20px;
    width: 100%;
    transition: 0.6s;

    .project_item {
      display: flex;
      align-items: center;
      width: 100%;
      cursor: pointer;

      &:not(:first-child) {
        margin-top: 15px;
      }
      svg:first-child {
        margin-right: 15px;
      }
    }
  }
  .add {
    padding-top: 20px;
    button {
      width: 100%;
      color: #383838;
      background-color: transparent;
      border: 1px solid #383838;

      &:disabled {
        cursor: not-allowed;
        color: #8a8a8a;
        border: 1px solid #8a8a8a;
      }
      svg:first-child {
        margin-right: 5px;
      }
    }
    .upgrade_notice {
      padding-top: 12px;
      color: rgb(221, 152, 93);
      a {
        color: rgb(138, 197, 111);
      }
    }
  }
}
