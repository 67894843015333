.widget_prompt_control {
  position: relative;

  background-color: rgb(248, 249, 250);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top: 1px solid #ededed;
  /*
 border-radius: 6px;
  border: 1px solid #ededed;
*/
  /*box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);*/

  .prompt_control_body {
    padding: 16px;

    .hidden_view {
      display: none;
    }
  }
}
