.model_api_console {
  /*box-shadow: 0 0.3em 1.2em rgba(204, 204, 204, 0.6);*/
  background-color: #fff;
  height: 100%;
  border-radius: 3px;
  padding: 16px 0 0;
}
.url_container {
  margin-top: 16px;
  margin-bottom: 24px;

  .http-method {
    color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border: 1px solid transparent;
    border-radius: 4px;
  }
  .rest-endpoint {
    margin-left: 10px;
  }
}
.request_container {
  display: grid;
  grid-template-columns: 1fr 36px 1fr;
  .label {
    color: #666;
    padding-bottom: 6px;
  }
  .between {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .response {
    position: relative;
    .progress {
      position: absolute;
      inset: 0;
      background-color: rgba(#fff, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.request_actions {
  display: grid;
  grid-template-columns: 1fr 36px 1fr;
  padding-top: 24px;
  .buttons {
    display: inline-flex;
    .btn {
      margin-bottom: 0;
    }
    & > div:not(:last-child) {
      margin-right: 42px;
    }
    .no-btn {
      margin-top: 10px;
      color: #333;
    }
    a {
      display: flex;
      align-items: center;
      color: #333;
      text-decoration: none;
      margin-top: 10px;
      &:visited,
      &:active,
      &:focus {
        color: #333;
        text-decoration: none;
      }
      svg {
        margin-right: 10px;
      }
    }
  }
  .response_preview {
    display: flex;
  }
  .img_container {
    width: 330px;
    height: 330px;
    position: relative;
    img {
      border-radius: 5px;
      /*box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15); */
      box-shadow: 0 8px 20px rgba(33, 33, 33, 0.4);
      max-height: 100%;
      max-width: 100%;
      width: auto;
      height: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  .link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 16px;
    a {
      color: #333;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-left: 6px;
      }
    }
  }
}
