.chatbot_reply_loading_state {
  display: flex;
  gap: 24px;
  padding: 24px;
  border-radius: 6px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
  background-color: #fff;

  .openai_avatar {
    display: flex;
    padding: 8px;
    border-radius: 6px;
    background-color: rgb(117, 169, 155);
  }
  .message_item {
    flex-grow: 1;
    color: #344767;
    line-height: 1.6rem;
    display: flex;
    flex-direction: column;
    gap: 6px;
    .reply_spinner {
      display: flex;
      gap: 6px;
      align-items: center;
    }
  }
}
