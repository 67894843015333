.create_api_key_dialog {
  .dialog_body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 32px;
    padding-bottom: 32px;

    .react-select {
      width: 100%;
    }
  }
  .buttons {
    column-gap: 12px;
  }
}
