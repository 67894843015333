.chatbot_indexed_pages {
  .indexed_page {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid rgb(198, 202, 206);
    & > div {
      padding: 12px;
      a,
      a:hover,
      a:active,
      a:visited {
        color: #333;
      }
    }
  }
}
