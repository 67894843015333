.account_settings_content_panel {
  h2,
  h3,
  h4 {
    color: #344767;
  }
  .summary {
    display: flex;
    flex-direction: column;
    color: #344767;
    & > div {
      display: flex;
      align-items: center;
      svg {
        margin-right: 12px;
      }
      &:not(&:last-child) {
        margin-bottom: 16px;
      }
    }
  }
  .change_password_panel {
    box-shadow: 0 0.3em 1.2em rgba(204, 204, 204, 0.6);
    background-color: #fff;
    border-radius: 16px;
    padding: 40px;
    margin-top: 24px;

    input {
      border: unset;
      font-size: 16px;
      padding: 9px 12px;
      border-radius: 5px;
      background-color: #f0f3f4;
      min-width: 220px;
      margin-bottom: 24px;

      &:focus,
      &:active {
        outline: none;
        /*box-shadow: 0 3px 6px rgba(77,64,132,0.3);*/
        box-shadow: 0 0 0 2px rgba(77, 64, 132, 0.3);
      }
    }

    .password_match {
      display: inline-flex;
      padding: 9px 12px;
      color: #664d03;
      background-color: #fff3cd;
      border-color: #ffecb5;
      margin-bottom: 24px;
    }
  }
}
