.chatbot_main_layout {
  display: grid;
  grid-template-columns: 300px 4fr 1fr;
  width: 100vw;
  height: 100vh;

  &.image_editor_layout {
    .logo {
      padding-left: 12px;
    }
  }

  &.full_width_layout {
    .content_panel {
      grid-column-start: 1 !important;
      grid-column-end: 6 !important;
    }
  }

  @media (max-width: 767.98px) {
    & {
      grid-template-rows: fit-content(54px) fit-content(54px) auto;
    }
  }
  @media (min-width: 768px) {
    & {
      grid-template-rows: fit-content(54px) auto;
    }
  }
  &.left_menu_collapsed {
    grid-template-columns: 74px 4fr 1fr;
    .sidebar-items {
      padding: 0px 5px 5px;
    }
  }

  header {
    grid-column-start: 1;
    grid-column-end: 6;

    /*background-color: rgb(38,41,46);*/
    /*background-color: #0e1219;
    color: rgb(231,231,231); */

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    /*box-shadow: 0px 10px 15px 15px rgba(0,0,0,0.43);*/

    .logo {
      height: 54px;
      display: flex;
      align-items: center;
      padding-left: 25px;
      a {
        /*color: rgb(231,231,231);*/
        /*color: #313d4d;*/
        text-decoration: none;
        color: #555;
        font-size: 24px;
        font-weight: 400;
        font-family: "Dela Gothic One", sans-serif;
        display: flex;
        align-items: center;
        svg {
          margin-right: 6px;
        }
      }
      span {
        /*background-color: rgb(245,106,12);*/
        background-color: rgb(112, 66, 193);
        padding: 0px 7px 3px 7px;
        margin-right: 5px;
        border-radius: 9px;
        color: #fff;
      }
      .global_nav {
        margin-left: 119px;
        .entry {
          display: inline-flex;
          align-items: center;
          margin: auto 24px;
          color: #660047;
          cursor: pointer;

          &:not(:last-child) {
            margin-right: 32px;
          }
          svg {
            margin-right: 8px;
          }
        }
      }
      .global_nav_xs {
        margin-left: 32px;
        .entry {
          display: inline-flex;
          align-items: center;
          color: #660047;
          cursor: pointer;
          padding: 6px;
          border-radius: 4px;
          background-color: rgba(174, 183, 194, 0.15);

          &:not(:last-child) {
            margin-right: 24px;
          }
        }
      }
    }

    .header_menu {
      /*margin-top: 4px;*/
      display: flex;
      align-items: center;

      .item {
        /*position: relative;*/
        display: flex;
        align-items: center;
        margin: auto 24px;
        color: #344767;
        cursor: pointer;
        svg {
          margin-right: 8px;
        }
        .entry {
          display: inline-flex;
          align-items: center;
          &:not(:last-child) {
            margin-right: 32px;
          }
        }
        .submenu {
          position: absolute;
          z-index: 1000;
          top: 32px;
          right: 2px;
          width: 140px;
          display: flex;
          flex-direction: column;
          padding: 24px;
          background-color: #fff;
          border-radius: 3px;
          color: #333;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.175);
          & > div {
            display: flex;
            align-items: center;
            cursor: pointer;
            &:not(:last-child) {
              margin-bottom: 24px;
            }
          }
        }
      }
    }
  }
  .mobile_trial {
    grid-column: span 6;
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 24px 8px;
    color: #660047;
  }
  .trial_summary {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid rgb(231, 232, 236);
    margin-top: 2px;
    border-radius: 4px;
    color: #333;
    padding: 5px 10px;
    &.soon {
      color: #664d03;
      background-color: #fff3cd;
      border-color: #ffecb5;
    }
    &.expired {
      color: #842029;
      background-color: #f8d7da;
      border-color: #f5c2c7;
    }
    .trial_text {
      max-width: 280px;
      font-size: 12px;
      line-height: 13px;
      font-weight: 500;
      padding-right: 5px;
    }
    .trial_button {
      display: flex;
      align-items: center;
      .btn {
        margin-bottom: 0;
      }
      .app-btn-green,
      .app-btn-red {
        padding: 4px 12px;
        font-size: 14px;
      }
    }
  }
  .left_panel {
    display: flex;
    flex-direction: column;
    /*transition: all 2s linear;*/
    transition: all 500ms ease-out;

    &.collapsed {
      .space_selector {
        padding: 15px;
        .current {
          display: flex;
          justify-content: center;
          svg {
            &:first-child {
              margin-right: 0;
            }
            &:last-child {
              display: none;
            }
          }
          div {
            display: none;
          }
        }
      }

      .sidebar-items {
        a {
          svg {
            margin-right: 0;
          }
          span {
            display: none;
          }
        }
      }
      .collapse_control {
        button {
          span {
            display: none;
          }
        }
      }
    }
  }
  .left_panel,
  .left_panel_mobile {
    /* #2b333e;*/

    /*background-size: cover;*/
    background-position: top center;
    background-repeat: no-repeat;

    color: #383838;
    padding: 0;
    /*
    box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
      0 0.25rem 0.53125rem rgba(#0e1219, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
    */
  }
  .left_panel_mobile {
    grid-column: span 6;
  }
  .space_selector {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    padding: 20px 30px;
    border-top: 2px solid rgba(255, 255, 255, 0.5);
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);

    @media (max-width: 767.98px) {
      & {
        margin-bottom: 0px;
      }
    }

    & > div:first-child {
      width: 100%;
      display: flex;
      align-items: center;
      img {
        height: 28px;
        margin-right: 15px;
        border-radius: 3px;
      }
      svg {
        width: 24px;
      }
      svg:first-child {
        margin-right: 15px;
      }
      svg:nth-child(2) {
      }
      .widen {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .current {
      cursor: pointer;
    }
    .options {
      background-color: rgba(255, 255, 255, 0.7);
      color: #383838;
      border-radius: 8px;
      padding: 15px;
      margin-top: 20px;
      width: 100%;
      transition: 0.6s;

      .project_item {
        display: flex;
        align-items: center;
        width: 100%;
        cursor: pointer;

        &:not(:first-child) {
          margin-top: 15px;
        }
        svg:first-child {
          margin-right: 15px;
        }
      }
    }
    .add {
      padding-top: 20px;
      button {
        width: 100%;
        color: #383838;
        background-color: transparent;
        border: 1px solid #383838;

        &:disabled {
          cursor: not-allowed;
          color: #8a8a8a;
          border: 1px solid #8a8a8a;
        }
        svg:first-child {
          margin-right: 5px;
        }
      }
      .upgrade_notice {
        padding-top: 12px;
        color: rgb(221, 152, 93);
        a {
          color: rgb(138, 197, 111);
        }
      }
    }
  }
  .sidebar-items {
    display: flex;
    flex-direction: column;
    padding: 0 30px 30px 30px;

    .icon_wrapper {
      background-color: #fff;
      padding: 10px 10px 7px;
      background-position: 50%;
      border-radius: 0.5rem;
      box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.12);
      margin-right: 16px;
    }

    a,
    a:visited,
    a:focus,
    a:active {
      display: flex;
      align-items: center;
      color: #67748e;
      font-size: 0.875rem;
      font-weight: 500;
      text-decoration: none;
      padding: 10px 16px;
      margin-bottom: 10px;
    }
    a.selected {
      color: #344767;
      font-weight: 600;
      box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
      border-radius: 0.5rem;
      background-color: #fff;

      .icon_wrapper {
        background-color: #344767;
        svg {
          stroke: #fff;
        }
      }
    }
    /*
    a:hover {
      border-radius: 8px;
      background-color: rgba(255, 255, 255, 0.5);
      
      .icon_wrapper {
        box-shadow: none;
        background-color: none;
      }
    }
    */
  }
  .collapse_control {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 32px;
    button {
      font-size: 16px;
    }
  }

  .space_menu_mobile {
    display: flex;
    /*justify-content: space-between;*/
    padding: 6px;
    a,
    a:visited,
    a:focus,
    a:active {
      display: flex;
      align-items: center;
      color: #660047;
      text-decoration: none;
      padding: 15px 20px;
    }
    a.selected {
      border-radius: 8px;
      background-color: #fff;
    }
  }

  .content_panel {
    overflow: auto;
    padding: 24px;
    padding-top: 8px;
    grid-column-start: 1;
    grid-column-end: 6;

    h3 {
      margin-top: 0;
    }
    .dropzone {
      border: 1px dashed gray;
      padding: 15px;
    }

    .content_panel_body {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      overflow: auto;
    }

    .panel {
      /*box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);*/
      box-shadow: 0 0.3em 1.2em rgba(204, 204, 204, 0.6);
      background-color: #fff;
      height: 100%;
      border-radius: 3px;
      padding: 40px;

      @media (max-width: 767.98px) {
        & {
          padding: 12px;
        }
      }
    }
  }
}
