.prompt_snapshots_panel {
  height: 100%;
  display: flex;
  flex-direction: column;
  .prompt_snapshots_body {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
    position: relative;
    .zero_state {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 18px;
      padding: 24px;
      color: #8392ab;
      .zero_text {
        text-align: center;
      }
    }
    .snapshot_list {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding-top: 16px;
      width: 100%;
      height: 100%;
      overflow: auto;

      .snapshot_item {
        display: flex;
        gap: 16px;
        align-items: center;
        cursor: pointer;
        padding: 16px;
        border-radius: 1rem;
        &.selected {
          background-color: #f8f8f8;
        }
        &:hover {
          background-color: rgb(248, 249, 250);
        }
      }

      .thumb_container {
        /*border-bottom: 1px solid rgb(232,236,239);*/
      }
      .thumb {
        border-radius: 9999px;
        width: 48px;
        height: 48px;
        box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.12) !important;
        /*
        &.recording {
          border-left: 5px solid #fbcf33;
        }
        &.ready {
          border-left: 5px solid #7928ca;
        }
        */
        &.selected {
          background-color: rgb(162, 202, 200);
        }
        &.approved {
          background-color: #b4ecb4 !important;
        }
        /*
        &.approved {
          border-left: 5px solid #17ad37;
        }
        &.draft {
          border-left: 5px solid #627594;
        }
        */

        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img {
          max-height: 100%;
          max-width: 100%;
          width: auto;
          height: auto;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }
      }
      .info {
        /*border-bottom: 1px solid rgb(232,236,239);*/
      }
      .title {
        color: #344767;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.5;
      }
      .subtitle {
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 1.25;
        color: #8392ab;
      }
      .action {
        /*border-bottom: 1px solid rgb(232,236,239);*/
        padding-bottom: 8px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
