.workspace_users_panel {
  .members_panel {
    margin-bottom: 32px;
  }
  .invitations_panel {
    margin-top: 24px;
  }
  .upgrade_notice {
    padding-top: 12px;

    padding: 12px 24px;
    background-color: #fff3cd;
    color: #856404;
    border-color: #ffeeba;
    border-radius: 6px;
    a {
      color: #07bc0c;
    }
  }
}
