.campaign_details_main {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: max-content auto;
    gap: 24px;

    .title_row {
        grid-column-start: 1;
        grid-column-end: 3;
        display: flex;
        justify-content: space-between;
    }
    .campaign_title {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.375;
        color: #344767;
        column-gap: 12px;

        .edit {
            cursor: pointer;
            height: 20px;
        }
        button {
            margin-bottom: 0; 
        }
    }

    .metadata_row {
        display: flex;
        column-gap: 12px;
        font-size: 14px;
        .meta_name {
            color: rgb(103,116,142);
        }
        .meta_value{
            color: #344767;
        }
    }
    .title_column {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
    }
    .title_actions {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        column-gap: 20px;

        .confirm_container {
            position: relative;                
            cursor: pointer;
            .cover {
                position: fixed;
                inset: 0;
              }
            .confirm_panel {
              position: absolute;
              z-index: 999;
              top: 12px;
              left: 0px;
              padding: 12px 14px;
              background-color: #fff;
              border: 1px solid #b8b8b8;
              border-radius: 5px;

              button {
                color: #fff;
                padding: 0.5rem 2rem;
                margin: 0;
              }
            }
          }
    }    
    .question_add_placeholder {
        display: flex;       
        align-items: center;
        justify-content: center;
        column-gap: 12px;
        color: #8392ab;
        cursor: pointer;
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.375;
        border: 1px solid #dee2e6;
        border-radius: 1rem;
        padding: 24px;
    }
    .campaign_submissions_body {
       display: flex;
       align-items: center;
       height: 100%;
       flex-direction: column;
       overflow: auto;

        .zero_state {
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 18px;
            padding: 24px;
            color: #8392ab;
            .zero_text {
                text-align: center;
            }
        }
        .submission_list {
            display: grid;
            grid-template-columns: 48px 1fr 100px;
            row-gap: 8px;
            padding-top: 16px;
            width: 100%;

            .thumb_container {
                cursor: pointer;
                /*border-bottom: 1px solid rgb(232,236,239);*/
                padding-bottom: 8px;
            }
            .thumb {
                
                background-color: rgb(162,202,200);
                border-radius: 4px;
                width: 56px;
                height: 56px;     
                padding: 8px;           
                box-shadow: 0 .3125rem .625rem 0 rgba(0,0,0,.12) !important;
                /*
                &.recording {
                    border-left: 5px solid #fbcf33;
                }
                &.ready {
                    border-left: 5px solid #7928ca;
                }
                &.approved {
                    border-left: 5px solid #17ad37;
                }
                &.rejected {
                    border-left: 5px solid #627594;
                }
                */
                display: flex;
                align-items: center;
                justify-content: center;     
                position: relative;
                img {
                    max-height: 100%;
                    max-width: 100%;
                    width: auto;
                    height: auto;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                  }
            }
            .info {
                cursor: pointer;
                padding-left: 16px;
                /*border-bottom: 1px solid rgb(232,236,239);*/
                padding-bottom: 8px;
            }
            .title {                
                color: #344767 ;
                font-weight: 600;
                font-size: .875rem;
                line-height: 1.5;

            }
            .subtitle {
                font-weight: 600;
                font-size: .75rem;
                line-height: 1.25;
                color: #8392ab;
            }
            .action {
                /*border-bottom: 1px solid rgb(232,236,239);*/
                padding-bottom: 8px;
                display: flex;
                justify-content: flex-end;
            }
        }
    }
    .question_form {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        

        textarea {
            font-size: 1rem;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
        }

        .question_actions {
            display: flex;
            column-gap: 12px;
        }
    }
    .question_item {
        display: flex;
        column-gap: 24px;
        border-radius: 0.75rem;
        background-color: #f8f9fa;
        /*color: #344767;*/
        color: #333;
        padding: 24px;
       
        .number {
            width: 42px;
            min-width: 42px;
            height: 42px;
            min-height: 42px;
            border: 2px solid #ccc;
            color: #555;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            padding: 12px;
        }
        .item_content {
            display: flex;
            align-items: center;
            /*white-space: pre;*/
            white-space: pre-wrap;
            word-break: normal;
            overflow-wrap: anywhere;
            flex-grow: 1;
            font-size: 1rem;
            margin: 0;
        }
        .item_record {
            display: flex;
            align-items: flex-start;
            .btn_rec {
                min-width: 75px;
                color: #344767;
                padding: 0;
            }
        }
        .item_form {
            display: flex;
            flex-grow: 1;
            textarea {
                font-size: 1rem;
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                sans-serif;
                    }
        }
        .item_actions {
            display: flex;
            align-items: flex-start;
            column-gap: 18px;
            button {
                font-size: 12px;
                font-weight: 700;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                color: #344767;
                margin: 0;
                padding: 0;
                svg {
                    margin-right: 0;
                }

                &.btn-text-icon {
                    svg {
                        margin-right: 6px;
                    }                    
                }
                &.bg-gradient-dark {
                    color: #fff;
                    padding: 0.3rem 1rem;
                }
            }
            .confirm_container {
                position: relative;                
                cursor: pointer;
                .cover {
                    position: fixed;
                    inset: 0;
                  }
                .confirm_panel {
                  position: absolute;
                  z-index: 999;
                  top: 24px;
                  left: -76px;
                  padding: 12px 14px;
                  background-color: #fff;
                  border: 1px solid #b8b8b8;
                  border-radius: 5px;

                  button {
                    color: #fff;
                    padding: 0.5rem 2rem;
                  }
                }
              }
        }
    }
    .campaign_questions_body {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
    }
    .submission_thumb_badge {       
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -8px;
        right: -8px;
        padding: 3px;
        border-radius: 50%;

        &.recording {
            background-color: #fbcf33;
        }
        &.ready {
            background-color: #7928ca;
        }
        &.approved {
            background-color: #17ad37;
        }
        &.rejected {
            background-color: #627594;
        }        
    }
}
.question_recording_dialog {
    & > div {
        width: 800px !important;
    }
    .recording_body {
        padding: 24px;
        display: flex;
        justify-content: center;
        video {
            transform: scaleX(-1);
        }
    }
    .buttons {
        column-gap: 12px;
    }
    .btn_repeat {
        color: #344767;
    }
}
.question_video_dialog {
    & > div {
        width: 800px !important;
    }
    .video_body {
        padding: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        row-gap: 32px;
        .question_text {
            width: 100%;
            padding: 16px;
            border: 1px solid #dee2e6;
            border-radius: .75rem;
        }
    }
    .buttons {
        column-gap: 12px;
    }
    .btn_repeat {
        color: #344767;
    }
}