.chat_list_main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  padding: 0 24px 24px;
  h1 {
    margin: 0;
    font-weight: 700 !important;
    font-size: 2rem;
    line-height: 1.625;
    color: #344767;
  }

  .content_header {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    padding-bottom: 16px;

    .breadcrumbs {
      display: flex;
      align-items: center;
      color: rgb(103, 116, 142);
      font-size: 0.875rem;
      & > div {
        display: flex;
        align-items: center;
        cursor: pointer;
        gap: 6px;
        flex-basis: fit-content;
      }
    }

    .toolbar_actions {
      display: flex;

      .action_buttons {
        display: flex;
        gap: 32px;
        flex-grow: 1;
      }
      .right_panel {
        padding-left: 48px;
        position: relative;
        .loading_container {
          position: absolute;
          top: 8px;
          left: 0;
        }
      }
    }
  }
  .chat_list_tiles {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
  }
}
