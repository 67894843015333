.hub_welcome_content {
  display: flex;
  flex-direction: column;

  h2,
  h3,
  h4 {
    color: #344767;
  }

  .dashboard_grid {
    flex-grow: 1;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 24px;

    .left {
      display: flex;

      .card {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
      .jobs_toolbar {
        display: flex;
        justify-content: flex-end;
        .btn {
          margin-bottom: 0;
        }
      }
      .zero_state_body {
        display: flex;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        height: 100%;

        .message_wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 24px;
          width: 320px;
        }
        .message_icon_wrapper {
          background-color: #f8f9fa;
          width: 96px;
          height: 96px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .message_text {
          text-align: center;
          color: rgb(103, 116, 142);
          font-size: 1rem;
        }
        .message_button {
          a,
          a:focus,
          a:visited,
          a:active {
            text-decoration: none;
          }
        }
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      gap: 24px;
      .balance_title {
        color: #8392ab !important;
        font-weight: 600 !important;
      }
      .balance_amount {
        color: #344767;
        font-size: 2rem;
        padding: 16px;
        background-color: rgb(248, 249, 250);
        border-radius: 0.8rem;
        margin-top: 12px;
      }
      .add_balance_container {
        padding-top: 16px;
        .btn {
          margin-bottom: 0;
        }
      }
      .other_models {
        flex-grow: 1;
      }
    }
  }
  .recent_jobs_section {
    h4 {
      display: flex;
      gap: 6px;
      align-items: center;
      margin-top: 0;
      margin-bottom: 12px;
    }
    .recent_jobs_list {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .job_list_headers {
        display: grid;
        gap: 16px;
        grid-template-columns: 260px 1fr 70px 60px 60px 80px;
        font-size: 13px;
        color: #67748e;
        padding: 12px;
        padding-bottom: 0;

        &:first-child {
          padding-left: 12px;
        }
        &:last-child {
          padding-right: 12px;
        }
      }
      .job_row {
        display: grid;
        gap: 16px;
        grid-template-columns: 260px 1fr 70px 60px 60px 80px;
        padding: 12px;
        background-color: #f8f9fa;
        cursor: pointer;

        .model_name {
          color: #344767;
          font-size: 14px;
          font-weight: 600;
        }
        .input_column {
          color: #344767;
          font-size: 13px;
        }
        .status {
        }
        .timing {
          color: #344767;
          font-size: 13px;
        }
        .created {
          color: #67748e;
          font-size: 13px;
        }
      }
    }
  }
}
